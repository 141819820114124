import React from "react";

const approachContent = [
  {
    title: "Understand",
    descriptions: `In this step, we'll learn about your requirement thoroughly discuss every steps associated and prepare a project plan, workflow and quotation for you.`,
    delayAnimation: "0",
  },
  {
    title: "Project Work",
    descriptions: `After placing the order, here we'll start your project work as per the prepared project plan and will deliver you within the given deadline.`,
    delayAnimation: "100",
  },
  {
    title: "Future",
    descriptions: `Here we'll fix any possible bug, check for security issues and sort out your queries and will make it ready to launch in the market.`,
    delayAnimation: "200",
  },
  {
    title: "Commitment",
    descriptions: `Your order is complete, all due payments are made. Still, you'll get lifetime After Sales support (error & bug fixes) and 1 year General Maintanance support. If you want dedicated support, you may consider purchasing a dedicated support plan from us.`,
    delayAnimation: "300",
  },
];

const Approach2 = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default Approach2;
