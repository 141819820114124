import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import { Link } from "react-router-dom";

const EcommerceSoftwareDemo = () => {
  return (
    <div>
      <Helmet>
        <title>Ecommerce Software Demo</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <Link to={"/mlm-software"}>
                      <a className="btn btn-warning mb-5">&larr; Go Back</a>
                    </Link>
                    <h1 className="large-heading">Ecommerce App Demos</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      These all are just demo, we'll customize your app and look
                      as per your requirements. <br />
                      ఇవన్నీ డెమో మాత్రమే, మేము మీ యాప్‌ని అనుకూలీకరించి, మీ
                      అవసరాలకు అనుగుణంగా చూస్తాము.
                      <br />
                      Todos estos son solo demostraciones, personalizaremos su
                      aplicación y buscaremos según sus requisitos.
                      <hr />
                      <Link to={"/contact"}>
                        <a className="btn btn-warning">Contact Us</a>
                      </Link>{" "}
                      &nbsp;
                      <Link to={"/ecommerce-software-features"}>
                        <a className="btn btn-primary">
                          Ecommerce Software Features
                        </a>
                      </Link>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-3">
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-primary text-white fw-bold">
                          Ecom Demo 2 (Standalone)
                        </div>

                        <div class="card-footer">
                          <a
                            target={"_blank"}
                            className="btn btn-warning btn-lg mt-4"
                            href="https://farmart.botble.com/"
                          >
                            Open Frontend Demo &rarr;
                          </a>
                          <hr />
                          <strong>User:</strong> botble
                          <br />
                          <strong>Pass:</strong> 159357
                          <br />
                          <a
                            target={"_blank"}
                            className="btn btn-primary btn-sm"
                            href="https://farmart.botble.com/admin/login"
                          >
                            Open Admin Panel &rarr;
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-primary text-white fw-bold">
                          Ecom Demo 1 (Woocommerce Version)
                        </div>

                        <div class="card-footer">
                          <a
                            target={"_blank"}
                            className="btn btn-warning btn-lg mt-4"
                            href="https://kalles.the4.co/demo/fashion/"
                          >
                            Open Frontend Demo &rarr;
                          </a>
                          <hr />
                          <strong>User:</strong> admin
                          <br />
                          <strong>Pass:</strong> admin
                          <br />
                          <a
                            target={"_blank"}
                            className="btn btn-primary btn-sm"
                            href="https://kalles.the4.co/demo/fashion/wp-login.php"
                          >
                            Open Admin Panel &rarr;
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EcommerceSoftwareDemo;
