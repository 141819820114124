import React from "react";
import { Helmet } from "react-helmet";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import HeaderDefault from "../../../components/header/HeaderDefault";

const BlockchainFuture = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>The world of Blockchain and Its Future</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">
                  Blockchain and Its Future
                </h1>
                <div className="ptf-single-post__meta">
                  <span className="cat">By</span>
                  <span className="date">Dr. Fahmida Yeasmin for Broken Pie</span>
                </div>
              </div>
            </header>


            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    {/* <!--Post Info--> */}
                    <div className="ptf-single-post__info">
                      <a className="author" href="#">
                        <i className="lnil lnil-user"></i>by <span>Dr. Fahmida Nishat Yeasmin</span>
                      </a>
                      <a className="view" href="#">
                        <i className="lnil lnil-eye"></i>1k+ Views
                      </a>
                      <a className="comments" href="#">
                        <i className="lnil lnil-comments"></i>0 Comments
                      </a>
                      <a className="report" href="#">
                        <i className="lnil lnil-warning"></i>Report
                      </a>
                    </div>

                    {/* <!--Post Excerpt--> */}
                    <div className="ptf-single-post__excerpt">
                      the future of blockchain technology looks bright, and when you consider that it’s already showing promise in almost every industry, it seems like the best is yet to come.
                      <span className="has-accent-1"> Blockchain technology </span>  has already been implemented in many fields, including digital identities, payment systems, cloud storage of data, smart contracts, issuance of cryptocurrencies (ICO), logistics management, and IoT transactions. These are expected to serve as new foundations for entirely new types of businesses and services in the future.
                    </div>

                    {/* <!--Post Content--> */}
                    <div className="ptf-single-post__content">
                      <p>
                        The very idea of blockchain was first introduced in 1991 by a group of researchers to timestamp
                        digital records so it would not be possible to temper them. It went mostly unused until it was
                        used by Satoshi Nakamoto for the creation of Bitcoin which gave rise to the discovery of
                        blockchain technology. It is an immutable distributed database maintaining a list of encrypted
                        blocks. Each block of code contains the data along with the history of every block that came
                        before it with time stamped transaction data down to second. Blockchain is basically made up of
                        two components i.e the decentralized network which facilitates and verifies every transaction
                        and the shared transaction ledger that the network maintains. The shared nature enables
                        blockchain to run without the need to build another infrastructure. It is an open-source
                        technology and uses cryptography to ensure that the data cannot be hacked and can be
                        accessed only by rightful parties.
                        Here we will explore different possibilities of how blockchain technology has the potential to
                        shape the future.<br /><br />
                        The blockchain is a “trustless system” which means that no trust is required between the
                        participants in a transaction because it verifies each transaction through the “proof-of-work”
                        (PoW) model to create a trustless model and also keeping a check on double-spending of
                        bitcoin. In such a technology the proof-of-work comes from the miners where they generate
                        PoW as the generated hash which in turn goes into the ledger. Thus in the real estate market
                        when it comes to agreements and paperworks, blockchains make the work hassle-free by
                        removing intermediaries and making everything transparent and temper-proof. When it comes
                        to online purchasing, blockchains can make the experience a lot smoother by ensuring that the
                        product is free from fraud and temperament. Also when we talk about online transactions it is
                        seen that the usage of blockchains makes transactions much more secure and faster without
                        taking days to complete the transaction unlike centralized systems.
                        Most of the time it is seen that in the healthcare system doctors and nurses struggle to deal with
                        the diverse medical records of the patients. Blockchains help to assess multiple data from
                        different timelines and different companies for each patient thus making the entire process
                        manifold easier and less time consuming.<br /><br />
                        Moreover there have been instances of startups like Follow My Vote which have been laying out
                        frameworks for digital voting systems. With the process of digital voting, the voting system would
                        become much more reliable as it would be almost impossible to temper with the votes in the
                        blockchains and also create faking digital voting ids. Hence blockchain not only possesses the
                        potential to impact the global economy but also has the power to impact the governance as a
                        whole.
                        The impact of blockchain has already been seen in different sectors and its future routes are
                        also being envisioned by many. It 's hard to predict a particular future for this technology but it
                        can be said that it is the digital fabric which holds the possibility to shape the world we live in.
                      </p>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "5rem", "--ptf-md": "2.5rem" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BlockchainFuture;
