import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import Footer from "../../../components/footer/Footer";

const SagaPay = () => {
  return (
    <div>
      <Helmet>
        <title>Saga Pay - A free cryptocurrency gateway</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page">
          <section className="min-vh-100">
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">

                <div className="col-lg-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h4 className="large-heading">
                      <strong>Saga Pay</strong> -
                      A Case Study
                    </h4>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem", "--ptf-md": "1.875rem" }}
                  ></div>
                  <blockquote style={{
                    borderLeft: `5px solid purple`,
                    paddingLeft: 10,
                    fontWeight: 300
                  }}>
                    They wanted to make Token as a Payment Option for everyone for free. Which was a challange for us to manage sub contract creation fee, managing asset security and so on.
                  </blockquote>

                </div>
                <div className="col-lg-3 text-center">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <img
                      style={{ maxWidth: 200 }}
                      src="https://sagapay.net/images/logo_dark.png"
                      alt="robot"
                      loading="lazy"
                    />
                  </div>

                </div>
                <div className="col-12" style={{
                  fontSize: 20
                }}>
                  <div>
                    <p style={{ marginLeft: '0cm' }}>With the revolution of cryptocurrency and digital payment gateways,the world is moving from physical payment methods to crypto payment methods. The modes of payment are evolving rapidly from physical money to digital money to cryptocurrency with the onset of the innovation brought by blockchain in the payment industry. Traditional payment methods due to their numerous limitations like involvement of intermediaries, delay in cross border payment, etc have faced major setbacks. This is where decentralized payment gateways have emerged to resolve the issues related to traditional payment methods by quickly validating the transactions and removing third parties from the payment system.</p>
                    <p style={{ marginLeft: '0cm' }}></p>
                    <p style={{ marginLeft: '0cm' }}>But one huge limitation in this payment method is the high gas fees associated with each transaction. Gas fees are inevitable since they compensate miners for the cost of energy required to verify a transaction and add it to the blockchain. This provides a layer of security by disincentivizing those wanting to spam the network. The amount of gas that a transaction requires is not constant across all transaction types, but rather is variable and largely dependent on two factors. The first is the computational complexity of the operation being executed. The computational complexity of any given operation depends on the amount of data that needs to be processed on the blockchain in order to complete the operation. As a general rule, as an operation becomes more complex the computational resources required to process the operation increases, hence resulting in higher gas fees.The second is network congestion. During periods where the network is congested from high transaction volume, users have to engage in gas pricing wars to increase the likelihood that their transaction is included in a block. This generally results in sharp increases in the gas fee required to submit an order on the network.</p>
                    <p style={{ marginLeft: '0cm' }}>We were approached by a company called Sagapay which wanted to provide an innovative cryptocurrency payment gateway for traders. Sagapay is a one of a kind cryptocurrency payment gateway which supports 100+ major coins like Bitcoin, Ethereum, Tron, BNB, Sol, etc and unlimited tokens. One unique feature of the Sagapay Payment Gateway is that they charge 0% withdrawal and deposit fee. But along with the excellent unique features came some challenges.</p>
                    <p style={{ marginLeft: '-0.01cm' }}><strong>THE CHALLENGES:</strong></p>
                    <p style={{ marginLeft: '0cm' }}>With the responsibility of creating such an unique payment gateway for a company we were mainly dawned upon by the following challenges:</p>
                    <p>To set up a crypto-payment gateway in such a way that the company doesn’t have to bear the burden of high, fluctuating gas-fees. It is well known that in order to compensate the miners to add transactions to public ledger and keep the network secure companies have to pay huge amounts of gas fees. Specially when there is traffic during transaction or the transaction needs to undergo through a lot of computational power.</p>
                    <p>The payments should be secure and it has to be made possible without the company managing its nodes. With the advent of technological advances, hacking is not a big deal. And so managing a cryptocurrency payment gateway which would be highly reliable and secure and that too with 0% withdrawal and deposit fees was quite a challenge.</p>
                    <p>The transactions should be super-fast so that traders can make hassle-free payments across the globe without time-consumption. Since transactions can get lagged when there is a huge traffic in the blockchain involved. Usually, the network congestion increases exponentially during peak hours and in bigger blockchain platforms like Ethereum.</p>
                    <p>An individual can use their token as the payment gateway. Part of making a user-friendly payment gateway is to make sure that the user is having the ease of making payments on their terms.</p>
                    <p style={{ marginLeft: '-0.01cm' }}><strong>KEY CHALLENGES:</strong></p>
                    <figure className="table">
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.3cm 0.2cm 0cm 0.17cm', verticalAlign: 'top' }}>
                              <p style={{ marginLeft: '0cm' }}>To make the company free of gas-fee.</p>
                            </td>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.3cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0cm' }}>To make the transactions safe and reliable.</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.3cm 0.2cm 0cm 0.17cm', verticalAlign: 'top' }}>
                              <p style={{ marginLeft: '0cm' }}>To make the transactions super-fast.</p>
                            </td>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.3cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0cm' }}>To enable usage of any token as payment gateway.</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                    <p style={{ marginLeft: '-0.01cm' }}></p>
                    <p style={{ marginLeft: '-0.01cm' }}><strong>THE SOLUTION</strong></p>
                    <p>Lifting the burden of gas fees was the highlight as well as a challenging task considering how inevitable it is in incentivize miners for their computational expenditure. So as a solution for the high transaction gas fees, we have created a unique smart contract for each user, which will redirect the payments to their hot-wallet. So whenever a user makes a transaction, the smart contract will automatically redirect the payment to hot-wallet hence making the company gas-fees free.</p>
                    <p>When it comes to cryptocurrency payment gateway or digital payment in general a major concern revolves around the reliability or how secure the transaction would be. Usually in order to keep transactions free from spammers and keeping them secure on the blockchains, companies have to pay high amounts of gas fees to miners. But here at Sagapay, whenever the user will deposit in the Sagapay payment gateway, a unique ID with a private key will be generated for each user and that particular private key will be encrypted and the address will be kept open. This will ensure the security of the transactions because even if anyone gets their hands on the private key, it will be impossible to decode it since they are end-to-end encrypted. Also the database of the transactions will be completely kept in a different setup. The credentials for accessing the database will be kept in Google Cloud KV Store which is highly secure and reliable. Hence it will be a next to impossible task for a hacker to get their hands on the database or the private key.</p>
                    <p>Infura is a blockchain development suite that provides application programming interfaces (APIs) and developer tools. Moreover, Infura provides fast and reliable access to the <a href="https://academy.moralis.io/blog/breaking-down-eth-2-0-the-next-generation-of-ethereum?utm_source=blog&utm_medium=post&utm_campaign=Infura%2520Explained%2520%25E2%2580%2593%2520What%2520is%2520Infura%253F">Ethereum network</a> to enable developers to build sophisticated next-generation software and Web3 applications that scale to meet user demand. Hence we can secure the payments without managing the nodes since we will be using quicknode or infura. But it is noteworthy that we will be using infura just for the initial payment. That is we will be using infura as an intermediary just to generate the address and process the transaction. The payment ultimately goes to the hot-wallet through the unique smart-contract. Hence it won’t be stuck or held in any middle address in the process of transaction and everything will be done super-fast and will be extremely safe.</p>
                    <p>And lastly a payment gateway is at its best when it is user friendly and the traders feel comfortable and at ease while using it. Hence the need of using anu token as payment gateway arises. So, in order to use any token as a payment gateway by the trader, we have created a unique smart contract. This smart contract can handle the payment and withdrawal of ERC20, TRC20, BEP20 tokens directly from our hot-wallet.</p>
                    <p style={{ marginLeft: '-0.01cm' }}><strong>MAJOR INNOVATIONS</strong></p>
                    <figure className="table">
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.29cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0cm' }}><strong>MAKING THE COMPANY GAS-FEES FREE</strong></p>
                              <p style={{ marginLeft: '0cm' }}>Generation of a smart contract which will redirect each transaction to the hot-wallet hence making the company free from the burden of gas fees.</p>
                            </td>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.29cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0.01cm' }}><strong>ENABLING RELIABLE TRANSACTION</strong></p>
                              <p style={{ marginLeft: '0.01cm' }}>Generation of a unique private key that is end-to-end encrypted and storage of the database for the credentials in Google Cloud KV.</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.29cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0cm' }}><strong>ENABLING SUPER FAST TRANSACTION</strong></p>
                              <p style={{ marginLeft: '0cm' }}>Usage of Infura as an intermediary and finally redirecting the transaction to the hot-wallet hence making the transaction super fast without getting held anywhere in between.</p>
                            </td>
                            <td style={{ border: '1.00pt solid #000000', padding: '0.29cm 0.2cm 0cm 0.17cm' }}>
                              <p style={{ marginLeft: '0.01cm' }}><strong>USAGE OF ANY TOKEN</strong></p>
                              <p style={{ marginLeft: '0.01cm' }}>Creation of a unique smart contract which lets individuals use any token as payment gateway.</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                    <p style={{ marginLeft: '-0.01cm' }}>&nbsp;</p>
                    <p style={{ marginLeft: '-0.01cm' }}><strong>CONCLUSION:</strong></p>
                    <p style={{ marginLeft: '0cm' }}>With the innovative initiatives being inculcated in the Sagapay Payment Gateway and also involving and bringing in solutions to some of the major limitations being faced by most of the companies trading in cryptocurrency payment system, we made happen the success of this unique payment gateway. With all the solutions being provided by us for the challenges that we were presented with, the company is running on smooth grounds and is getting highly benefited by out venture.</p>
                    <h5 style={{ marginTop: 10, fontWeight: 400 }}>Know more at <a target={'_blank'} href="https://sagapay.net">www.sagapay.net</a></h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "14.375rem", "--ptf-md": "7.1875rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SagaPay;
