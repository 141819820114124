import React from 'react';

const Card = ({ title, subtitle, content }) => {
    const cardStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '300px',
        height: '500px',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#04016A',
        color: '#fff',
        padding: 10
    };

    const imageStyles = {
        width: '100%',
        height: '70%',
        textAlign: 'center'
    };

    const contentStyles = {
        width: '100%',
        height: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '0',
        backgroundColor: '#fff',
        padding: '20px',
    };

    const titleStyles = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '5px',
        textAlign: 'center',
    };

    const subtitleStyles = {
        fontSize: '1rem',
        textAlign: 'center',
        color: '#000'
    };

    return (
        <div style={cardStyles}>
            <div style={imageStyles}>{content}</div>
            <div style={contentStyles}>
                <h2 style={titleStyles}>{title}</h2>
                <p style={subtitleStyles}>{subtitle}</p>
            </div>
        </div>
    );
};

export default Card;
