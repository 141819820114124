import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import Footer from "../../../components/footer/Footer";
import PortfolioListing from "../../../components/portfolio/PortfolioListing";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";

const Overview = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-listing">
      <Helmet>
        <title>Broken Pie - Overview</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <div className="ptf-page ptf-page--portfolio-listing">
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>

              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-10">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">Overview</h1>
                    </div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem" }}
                ></div>
              </div>
              {/* End .container-xxl */}

              {/*=============================================
                Start Portfolio main 
                ============================================== */}
              <div className="container-xxl" style={{
                fontSize:28
              }}>
             <p>Welcome to Broken Pie Private Limited, an Indian software company at the forefront of technological innovation, shaping the future with our work in Software Development, Gaming, Robotics, Blockchain, and IoT appliances. We thrive at the intersection of creativity, technology, and business.</p> 

<p>Since our inception, we have been driven by a singular passion: to craft unique, effective, and user-centric digital solutions that drive growth and success for our clients. We are more than just a software company; we are a team of passionate technologists, software engineers and ethical hackers, dedicated to our vision of making digital technology more accessible and productive for businesses and individuals alike.</p>

<p>Our Software Development department is skilled in designing and developing cutting-edge software solutions. We understand that in today's ever-evolving digital landscape, a one-size-fits-all approach doesn't cut it. Hence, we provide custom-made solutions, tailored to our clients' unique needs, ensuring their business objectives are met, and success is guaranteed.</p>

<p>Our Game Development team is committed to creating immersive experiences that captivate and engage users. We believe in pushing the boundaries of imagination, creating games that are not only fun and engaging but also provide a unique and memorable experience. We specialize in designing games across various platforms, offering unparalleled user experiences.</p>

<p>At Broken Pie, we have a dedicated Robotics division, where we are shaping the future of automation. Our team is continually exploring, learning, and pushing the boundaries of what is possible with robotics. We design and develop robotics solutions that improve productivity, efficiency, and quality of work, transforming the way businesses operate.</p>

<p>Our work in the Blockchain sector is trailblazing. We leverage this revolutionary technology to develop decentralized applications and solutions that enhance security, transparency, and efficiency. We are committed to helping businesses realize the full potential of blockchain technology, providing them with solutions that are both innovative and trustworthy.</p>

<p>In the realm of IoT, we provide smart, seamless, and secure solutions, connecting devices, and systems to enhance functionality and productivity. We are dedicated to transforming homes and businesses into smart environments, harnessing the power of IoT to simplify and improve everyday tasks.</p>

<p>We at Broken Pie, pride ourselves on our commitment to innovation, excellence, and customer satisfaction. Our work reflects our deep understanding of technology, our creative approach, and our unwavering commitment to delivering the best possible solutions. We are not just technologists, we are dreamers and doers, committed to making a difference in the world with our innovative solutions.</p>

<p>
Additionally, we provide IT consultation services, offering our expertise to businesses seeking to optimize their digital operations. We understand that each business has unique IT requirements, and our team of experts is ready to provide bespoke strategies and solutions to meet these needs. We offer comprehensive guidance, from infrastructure design and implementation to system optimization and scaling.
</p>
<p>
At Broken Pie Private Limited, we are continuously evolving, growing, and innovating, but our mission remains the same: to harness the power of technology to build a better future. Our commitment to providing top-notch, user-centric solutions underlines every project we undertake. We work in tandem with our clients, creating partnerships that thrive on mutual growth and success.
</p>
<p>
As we continue our journey, we promise to stay at the forefront of technology, delivering innovative solutions that exceed expectations. We invite you to be a part of this exciting journey. Together, we can transform ideas into reality, shape businesses, and create an impact in the digital world. With Broken Pie, you're not just choosing a service provider; you're choosing a partner dedicated to your success.
</p>
<hr/>
<div style={{
  float:'right'
}}>
<strong style={{
  color:'#000',
  fontWeight:400
}}>-Dr. Jyotirmoy Sarma (Technology Director)</strong><br/>
<img src="/assets/misc/signature.png" style={{
  maxWidth:300,
  marginTop:20
}} className="img-fluid" />
</div>
              </div>
              {/* <!--Spacer--> */}


              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default Overview;
