import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import Footer from "../../components/footer/Footer";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";

const BpieGurranted = () => {
  return (
    <div>
      <Helmet>
        <title>Broken Pie Guaranteed</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--pricing">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      What is Broken Pie Guaranteed ?
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-24">
                      Your money and time is safe. Learn more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div className="container-xxl">
              <div
                className="row mt-3"
                style={{
                  borderTopWidth: 1,
                  borderTopColor: "#ccc",
                  borderTopStyle: "solid",
                }}
              >
                <div className="col-sm-6 fz-24 mt-3">
                  We want our client to smile with us every time they place an
                  order. That's why we have created our customer safe policy
                  called "Broken Pie Guaranteed."
                  <blockquote className="mt-4">
                    <u>
                      You'll get 100% refund of paid amount + 5% annual Interest
                    </u>{" "}
                    on top of your paid amount if we are unable to deliver your
                    project by deadline + 2 Working days.
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  हम चाहते हैं कि हमारे क्लाइंट हर बार ऑर्डर देने पर हमारे साथ
                  मुस्कुराएं। इसलिए हमने "ब्रोकन पाई गारंटीड" नामक अपनी ग्राहक
                  सुरक्षित नीति बनाई है।
                  <blockquote className="mt-4">
                    यदि हम समय सीमा + 2 कार्य दिवसों द्वारा अपना काम देने में
                    असमर्थ हैं, तो आपको अपनी भुगतान की गई राशि के शीर्ष पर{" "}
                    <u>100% रिफंड + 5% वार्षिक ब्याज मिलेगा।</u>
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  మా క్లయింట్ వారు ఆర్డర్ చేసిన ప్రతిసారీ మాతో నవ్వాలని మేము
                  కోరుకుంటున్నాము. అందుకే "బ్రోకెన్ పై గ్యారెంటీడ్" అనే మా
                  కస్టమర్ సేఫ్ పాలసీని రూపొందించాము.
                  <blockquote className="mt-4">
                    గడువు + 2 పని దినాల ద్వారా మేము మీ పనిని అందించలేకపోతే మీ
                    చెల్లింపు మొత్తంలో మీరు{" "}
                    <ul>100% వాపసు + 5% వార్షిక వడ్డీని పొందుతారు.</ul>
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  ഞങ്ങളുടെ ഉപഭോക്താവ് ഒരു ഓർഡർ നൽകുമ്പോഴെല്ലാം ഞങ്ങളോടൊപ്പം
                  പുഞ്ചിരിക്കണമെന്ന് ഞങ്ങൾ ആഗ്രഹിക്കുന്നു. അതുകൊണ്ടാണ് "ബ്രോക്കൺ
                  പൈ ഗ്യാരന്റിഡ്" എന്ന പേരിൽ ഞങ്ങളുടെ ഉപഭോക്തൃ സുരക്ഷിത നയം
                  ഞങ്ങൾ സൃഷ്ടിച്ചത്.
                  <blockquote className="mt-4">
                    നിങ്ങളുടെ പണമടച്ച തുകയുടെ മുകളിൽ 100% റീഫണ്ട് + 5% വാർഷിക
                    താൽപ്പര്യം നിങ്ങൾക്ക് ലഭിക്കുക + 2 പ്രവൃത്തി ദിവസങ്ങൾ
                    അനുസരിച്ച് നൽകാനാവില്ലെങ്കിൽ.
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  আমি বিচাৰো যে আমাৰ ক্লায়েণ্টে প্ৰতিবাৰ অৰ্ডাৰ দিয়াৰ সময়ত
                  আমাৰ লগত হাঁহিব। সেইবাবেই আমি আমাৰ গ্ৰাহক সুৰক্ষিত নীতি তৈয়াৰ
                  কৰিছো যাৰ নাম "Broken Pie Guaranteed"।
                  <blockquote className="mt-4">
                    আপুনি আপোনাৰ পৰিশোধ কৰা ৰাশিৰ ওপৰত ১০০% ৰিফাণ্ড + ৫% বাৰ্ষিক
                    সুত পাব যদিহে আমি সময়সীমা+ ২ কৰ্মদিনৰ ভিতৰত আপোনাৰ কাম
                    আগবঢ়াব নোৱাৰো|
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  Ons wil hê ons kliënt moet saam met ons glimlag elke keer as
                  hulle 'n bestelling plaas. Dit is hoekom ons ons
                  kliënteveilige beleid genaamd "Gebreekte pastei gewaarborg"
                  geskep het.
                  <blockquote className="mt-4">
                    U kry 100% terugbetaling + 5% jaarlikse rente bo -op u
                    betaalde bedrag as ons nie u werk teen sperdatum + 2
                    werksdae kan aflewer nie.
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  نريد أن يبتسم عملائنا معنا في كل مرة يقدمون فيها طلبًا. لهذا
                  السبب قمنا بإنشاء سياسة آمنة للعملاء تسمى "الفطيرة المكسورة
                  مضمونة".
                  <blockquote className="mt-4">
                    ستحصل على استرداد 100 ٪ + 5 ٪ فائدة سنوية أعلى المبلغ
                    المدفوع إذا لم نتمكن من تقديم عملك بحلول الموعد النهائي + 2
                    أيام العمل.
                  </blockquote>
                </div>
                <div className="col-sm-6 fz-24 mt-3">
                  Queremos que nuestro cliente sonría con nosotros cada vez que
                  haga un pedido. Es por eso que hemos creado nuestra política
                  de seguridad para el cliente llamada "Pastel roto
                  garantizado".
                  <blockquote className="mt-4">
                    Obtendrá el 100% de reembolso + 5% de interés anual además
                    de su monto pagado si no podemos entregar su trabajo
                    mediante fecha límite + 2 días hábiles.
                  </blockquote>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End pricing top section title */}
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BpieGurranted;
