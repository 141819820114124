import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../social/Social";

const menuContent = [
  {
    name: "About",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    dropDownItems: [
      {
        name: "Know Us",
        routerPath: "/about",
      },
      {
        name: "Overview",
        routerPath: "/overview",
      },
      {
        name: "Non Profit Works",
        routerPath: "/ngo",
      },
      {
        name: "Career",
        routerPath: "/career",
      },
      {
        name: "Media",
        routerPath: "/media",
      },
      {
        name: "BPie Guaranteed",
        routerPath: "/bpie-gurranted",
      },
    ],
  },
];

const menuContent2 = [
  {
    name: "Products",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    dropDownItems: [
      {
        name: "Network Marketing Software",
        routerPath: "/mlm-software",
      },
      {
        name: "NBFC & Banking Software",
        routerPath: "/nbfc-software",
      }
    ],
  },
];

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              <MenuItem>
                <Link
                  style={{ color: "#000", fontSize: 28, fontWeight: "bold" }}
                  to="/"
                >
                  Home
                </Link>
              </MenuItem>
              {menuContent.map((item, i) => (
                <SubMenu title={item.name} key={i}>
                  {item.dropDownItems.map((val, i) => (
                    <MenuItem key={i}>
                      <Link to={val.routerPath}>{val.name}</Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              ))}
              {menuContent2.map((item, i) => (
                <SubMenu title={item.name} key={i}>
                  {item.dropDownItems.map((val, i) => (
                    <MenuItem key={i}>
                      <Link to={val.routerPath}>{val.name}</Link>
                    </MenuItem>
                  ))}
                </SubMenu>
              ))}
              {/* <MenuItem>
                <Link style={{ color: '#000', fontSize: 28, fontWeight: 'bold' }} to="/blog">Blog</Link>
              </MenuItem> */}
              <MenuItem>
                <Link
                  style={{ color: "#000", fontSize: 28, fontWeight: "bold" }}
                  to="/services"
                >
                  Services
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  style={{ color: "#000", fontSize: 28, fontWeight: "bold" }}
                  to="/apps"
                >
                  Showcase
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  style={{ color: "#000", fontSize: 28, fontWeight: "bold" }}
                  to="/contact"
                >
                  Contact
                </Link>
              </MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}
      <h4>
        <a href="tel:18008916355">1800 891 6355</a>
      </h4>
      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          &copy; Broken Pie Private Limited
          <br />
          CIN: U72900AS2020PTC020515
        </p>
        <Social />
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
