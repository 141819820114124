import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  { itemName: "Term & Conditions", link: "/terms" },
  { itemName: "Privacy Policy", link: "/privacy" },
  { itemName: "Help", link: "/contact" },
  { itemName: "Works", link: "/works" },
  { itemName: "Non Profit", link: "/ngo" },
];

const FooterList = () => {
  return (
    <ul>
      {footerItems.map((item, i) => (
        <li key={i}>
          <Link to={item.link}>{item.itemName}</Link>
        </li>
      ))}
      <li>
        <a target={'_blank'} href="https://blog.brokenpie.com">Blog</a>
      </li>
    </ul>
  );
};

export default FooterList;
