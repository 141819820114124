import React from "react";
import { Link } from "react-router-dom";
import FooterList from "../list/FooterList";

const linkList = [
  {
    itemName: "Faqs",
    link: "/",
  },
  {
    itemName: "Term & Conditions",
    link: "/",
  },
  {
    itemName: "Privacy Policy",
    link: "/",
  },
  {
    itemName: "Help",
    link: "/",
  },
  {
    itemName: "Affiliate",
    link: "/",
  },
  {
    itemName: "Services",
    link: "/",
  },
];

const Product = [
  {
    itemName: "Works",
    link: "/",
  },
  {
    itemName: "About",
    link: "/",
  },
  {
    itemName: "News",
    link: "/",
  },
  {
    itemName: "Contact",
    link: "/",
  },
];

const Footer = () => {
  return (
    <div className="row">
      <div className="col-12 col-sm-8">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div
            className="ptf-footer-heading has-black-color"
            style={{ maxWidth: "37.5rem" }}
          >
            Boost Your Business Up To High Level
          </div>

          <div className="ptf-footer-heading has-black-color">
            Start by
            <Link
              className="has-accent-1 text-decoration-underline"
              to="/contact"
            >
              &nbsp;Saying Hi!
            </Link>
          </div>

          <p className="fz-24">
            1st Floor, HN9, Sarvodaya Path, ABC, Guwahati, Assam,
            <br /> India 781005 (11 AM - 5 PM IST)
          </p>
          <a className="fz-40 has-black-color" href="mailto:hello@moonex.co">
            hello@brokenpie.com
          </a>
        </div>
      </div>
      <div className="col-12 col-sm-4">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-black-color">
            <h4 className="ptf-widget-title">Links</h4>
            <FooterList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
