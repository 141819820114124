import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import Approach3 from "../../../../components/service/Approach3";
import 'react-awesome-slider/dist/styles.css';
import Pricing from "../../../../components/service/Pricing";
import SlackForm from "../../../../components/service/SlackForm";
import { salesemail, softwarephone } from "../../../../Const";

export default function ExploreBlockchain() {

  return (
    <div>
      <Helmet>
        <title>Explore the world of Blockchain & Metaverse</title>
        <meta name="content" description="Blockchain and Metaverse technologies are reshaping the future by offering users a secure, decentralized way of operating that can be customized to their exact needs. Know more here." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main m-2">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            <div className="container-xxl mt-5">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Explore the world of <strong>Blockchain & Metaverse</strong>

                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      The future of metaverse is limitless possibilities and opportunities to construct a new way of living, providing greater connectivity and interactivity with the world around us.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}

                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="h5">Few Examples Where we can use Blockchain:</h2>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/1.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/2.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/3.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/4.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/5.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/blockchain/6.png" />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h4 style={{
                    fontWeight: 500
                  }}>What is Blockchain and How you can setup a billion dollar business with it ?</h4>
                </div>
                <div className="col-12" style={{
                  color: '#000',
                  textAlign: 'justify',
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: 15,
                  fontSize: 20,
                  backgroundColor: '#fff',
                  boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                  borderRadius: 10
                }}>
                  Blockchain technology is a revolutionary digital ledger that securely records transactions across multiple computers, ensuring transparency, security, and decentralization. It's the driving force behind cryptocurrencies, but its applications extend far beyond, touching every industry from finance to supply chain management, healthcare, and more.
                  <br />
                  <br />
                  At Broken Pie, we harness the power of blockchain to create solutions that redefine business operations, delivering efficiency, transparency, and unparalleled security. Whether you're aiming to streamline your supply chain, create a secure digital identity system, or even launch your own cryptocurrency, we have the expertise to make it happen. Imagine a business where transactions are secure and instantaneous, operations are transparent, and trust is inherent. With Broken Pie, this isn't a distant dream, but a tangible, achievable reality.
                  <br />
                  <br />
                  Together, let's leverage the potential of blockchain technology and set up your billion-dollar business. Let's break the traditional boundaries and bake a pie of innovation and success. Partner with Broken Pie today and transform the way you do business, creating a future-proof organization ready for the digital age.
                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <div className="row">
                  <div className="col-sm-4">
                    <h3 style={{
                      borderColor: '#ccc',
                      borderWidth: 5,
                      borderStyle: 'solid',
                      padding: 10,
                      marginBottom: 50
                    }}>
                      <p>
                        Call Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"tel:" + softwarephone}>{softwarephone}</a>
                      </p>
                      <p>
                        Email Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"mailto:" + salesemail}>{salesemail}</a>
                      </p>
                    </h3>
                  </div>
                  <div className="col-sm-8" style={{
                    padding: 10,
                    borderWidth: 5,
                    borderStyle: 'solid',
                    borderColor: '#dfdfdf',
                    borderRadius: 10,
                    margin: 'auto',
                    alignSelf: 'center',
                    backgroundColor: '#fff'
                  }}>
                    <img src="/assets/blockchain/card.png" className="img-fluid" />
                  </div>
                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <blockquote style={{
                  borderLeftColor: 'purple',
                  borderLeftWidth: 10,
                  borderLeftStyle: 'solid',
                  fontWeight: 200
                }}>
                  According to a report by MarketsandMarkets, the blockchain market is expected to grow from $3 billion in 2020 to $39.7 billion by 2025, at a Compound Annual Growth Rate (CAGR) of 67.3% during the forecast period.
                  <br /><br />
                  Furthermore, a report by PwC suggests that blockchain could add $1.76 trillion to the global gross domestic product (GDP) by 2030, with potential benefits including increased efficiency, reduced fraud and corruption, and improved transparency.
                  <br /><br />
                  As per research, metaverse is going to become a <strong>billion-dollar industry in the future</strong>. The potential of the metaverse to create new opportunities for entertainment, socialization, and commerce is significant, and as more people become familiar with the technology, the demand for it is likely to increase. Companies are already investing heavily in the metaverse, and it is expected that this trend will continue as the technology develops further.
                </blockquote>
              </div>
              <div className="col-12">
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.875rem" }}
                ></div>
                <Pricing />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "2rem" }}
                ></div>
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h3>Whats Next ?</h3>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                </div>
                <Approach3 />
              </div>
              <div className="row">

                <div className="col-sm-6">
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                  <SlackForm />
                </div>
                <div className="col-sm-6">
                  <a href="https://blog.brokenpie.com/how-blockchain-based-business-can-generate-revenue/" target={'_blank'}>
                    <img alt="Blockchain Blog" src="/assets/blockchain/blog.png" style={{
                      maxWidth: 400,
                      width: '100%',
                      margin: 'auto',
                      borderRadius: 10,
                      marginTop: 20
                    }} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
