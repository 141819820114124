import React from "react";
// Homepage Demo
import HomeStudio from "../views/all-home-version/HomeStudio";
// Service
import Service from "../views/inner-pages/service/Service";
import MLMSoftware from "../views/inner-pages/service/mlm/MLMSoftware";

// About
import AboutUs from "../views/inner-pages/about/AboutUs";


// Portfolio
import WorksListing from "../views/inner-pages/portfolio/Overview";

// Others
import Contact from "../views/inner-pages/Contact";
import Faq from "../views/inner-pages/Faq";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import IsWeb3Myth from "../views/inner-pages/blog/IsWeb3Myth";
import BlockchainFuture from "../views/inner-pages/blog/BlockchainFuture";
import AiRobotics from "../views/inner-pages/blog/AiRobotics";
import NGO from "../views/inner-pages/portfolio/ngo";
import Career from "../views/inner-pages/Career";
import Terms from "../views/inner-pages/Terms";
import Privacy from "../views/inner-pages/Privacy";
import Media from "../views/inner-pages/Media";
import MLMSoftwareDemo from "../views/inner-pages/service/mlm/MLMSoftwareDemo";
import MLMSoftwareFeatures from "../views/inner-pages/service/mlm/MLMSoftwareFeatures";
import BpieGurranted from "../views/inner-pages/BpieGurranted";
import EcomSoftware from "../views/inner-pages/service/ecommerce/EcomSoftware";
import EcommerceSoftwareDemo from "../views/inner-pages/service/ecommerce/EcommerceSoftwareDemo";
import EcommerceSoftwareFeatures from "../views/inner-pages/service/ecommerce/EcommerceSoftwareFeatures";
import SagaPay from "../views/inner-pages/portfolio/sagapay";
import NBFCSoftware from "../views/inner-pages/service/nbfc/NBFCSoftware";
import ExploreBlockchain from "../views/inner-pages/service/extras/ExploreBlockchain";
import ExploreSoftware from "../views/inner-pages/service/extras/ExploreSoftware";
import SnogCab from "../views/inner-pages/portfolio/snogcab";
import HniProtocol from "../views/inner-pages/portfolio/hniprotocol";
import Apps from "../views/inner-pages/Apps";
import ExploreGame from "../views/inner-pages/service/extras/ExploreGame";
import Overview from "../views/inner-pages/portfolio/Overview";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={HomeStudio} />

          <Route path="/explore-blockchain" component={ExploreBlockchain} />
          <Route path="/explore-software" component={ExploreSoftware} />
          <Route path="/game-development" component={ExploreGame} />

          {/* Service */}
          <Route path="/apps" component={Apps} />
          <Route path="/services" component={Service} />

          {/* About */}
          <Route path="/about" component={AboutUs} />

          {/* Blog */}
          <Route path="/web3-truth-myth" component={IsWeb3Myth} />
          <Route
            path="/the-world-of-blockchain-and-future"
            component={BlockchainFuture}
          />
          <Route path="/ai-robotics-future" component={AiRobotics} />

          {/* Portfolio */}
          <Route path="/overview" component={Overview} />
          <Route path="/ngo" component={NGO} />

          {/* Case Studies */}
          <Route path="/case-studies/sagapay" component={SagaPay} />
          <Route path="/case-studies/snog" component={SnogCab} />
          <Route path="/case-studies/hniprotocol" component={HniProtocol} />

          {/* others */}
          <Route path="/bpie-gurranted" component={BpieGurranted} />
          <Route path="/terms" component={Terms} />
          <Route path="/media" component={Media} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/career" component={Career} />
          <Route path="/contact" component={Contact} />
          <Route path="/faq" component={Faq} />
          <Route path="/ecommerce-software" component={EcomSoftware} />
          <Route
            path="/ecommerce-software-demo"
            component={EcommerceSoftwareDemo}
          />
          <Route
            path="/ecommerce-software-features"
            component={EcommerceSoftwareFeatures}
          />
          <Route path="/mlm-software" component={MLMSoftware} />
          <Route path="/mlm-software-demo" component={MLMSoftwareDemo} />
          <Route
            path="/mlm-software-features"
            component={MLMSoftwareFeatures}
          />
          <Route path="/nbfc-software" component={NBFCSoftware} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
