import React from 'react';

const AppCard = ({ title, subtitle, header, content, url }) => {
    const cardStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '300px',
        height: '220px',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        position: 'relative',
        color: '#fff',
        padding: 10
    };

    const contentStyles = {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '0',
        backgroundColor: '#fff',
        padding: '20px'
    };

    const titleStyles = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: '5px',
        textAlign: 'center',
        marginTop: 20
    };

    const subtitleStyles = {
        fontSize: '1rem',
        textAlign: 'center',
        color: '#000'
    };


    return (
        <div style={cardStyles}>
            <div style={contentStyles}>
                <h2 style={titleStyles}>{title}</h2>
                <p style={subtitleStyles}>{subtitle}</p>
                <a style={{
                    border: `1px solid #ccc`,
                    padding: 10,
                    borderRadius: 5
                }} className='m-3' target='_blank' href={url}><h5>view &rarr;</h5></a>
            </div>
        </div>
    );
};

export default AppCard;
