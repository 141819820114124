import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import Footer from "../../components/footer/Footer";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";
import AppCard from "./service/AppCard";

const Apps = () => {
  return (
    <div>
      <Helmet>
        <title>Some Portfolio of Broken Pie</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--pricing">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Broken Pie Showcase</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-24">
                      Showcasing Unique Achievements: What Broken Pie Has Accomplished So Far
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-sm-4 mt-4">
                  <AppCard title={'Wild Win Game'} url={'https://wildwin.fun'} subtitle='Play with skill, Game of Survival' />
                </div>
                <div className="col-sm-4 mt-4">
                  <AppCard title={'Saga Pay'} url={'https://sagapay.net'} subtitle='Free Crypto Payment Gateway' />
                </div>
                <div className="col-sm-4 mt-4">
                  <AppCard title={'Snog Cab'} url={'https://snogweb.com'} subtitle='Blockchain based Taxi App' />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 mt-4">
                  <AppCard title={'Poly Global'} url={'https://polyglobal.io'} subtitle='NFT staking platform for profit' />
                </div>
                <div className="col-sm-4 mt-4">
                  <AppCard title={'Tron Jackpot'} url={'https://tronjackpot.online'} subtitle='Crypto based Online Lottery' />
                </div>
                <div className="col-sm-4 mt-4">
                  <AppCard title={'HNI Protocol'} url={'https://hniprotocol.com'} subtitle='Blockchain developed on Hyperledger' />
                </div>
              </div>
            </div> <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          <h3 style={{
            textAlign: 'center',
            fontWeight: 400,
            fontStyle: 'italic',
            margin: 50,
            color: 'grey',
            fontFamily: 'fantasy'
          }}>2000+ Apps delivered so far..</h3>

        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Apps;
