import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import ServiceListThree from "../../../../components/list/ServiceListThree";
import Faq from "../../../../components/faq/Faq";
import Skills from "../../../../components/skill/Skills";
import Approach2 from "../../../../components/service/Approach2";
import { Link } from "react-router-dom";
import EcomPricing from "../../../../components/pricing/EcomPricing";

export default function EcomSoftware() {
  const [phone, setPhone] = useState(null);
  const [country, setCountry] = useState("+91");
  const [process, setProcess] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendSMS = async () => {
    if (phone == "" || !phone) {
      return alert("Oops ! Phone no is required.");
    }
    setProcess(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("type", "Ecommerce Software");
    urlencoded.append("phone", country + phone);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      "https://asia-south1-brokenpie.cloudfunctions.net/leadsManagement/call-me",
      requestOptions
    );
    setSuccess(true);
  };

  return (
    <div>
      <Helmet>
        <title>Ecommerce Software @ $ 130 USD</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Ecommerce Software <br />@ ₹ 10,000 INR{" "}
                      <sup style={{ fontSize: 14, color: "green" }}>Starts</sup>
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      With Unlimited Payment Gateway Integration, Unlimited
                      Products, Unlimited Customers and Courier Tracking
                      Integration
                      <hr />
                      <Link to={"/ecommerce-software-demo"}>
                        <a className="btn btn-warning">
                          Ecommerce Software Demo
                        </a>
                      </Link>{" "}
                      &nbsp;
                      <Link to={"/ecommerce-software-features"}>
                        <a className="btn btn-primary">
                          Ecommerce Software Features
                        </a>
                      </Link>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  {/* <!--Animated Block--> */}
                  {/* <ImageGridFour /> */}
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            <div className="container">
              <div className="row">
                <div className="col">
                  {success ? (
                    <div
                      className="alert alert-success"
                      style={{ maxWidth: 600 }}
                    >
                      Thank you ! We'll Call you soon.
                    </div>
                  ) : (
                    <div
                      class="input-group mb-3"
                      style={{
                        maxWidth: 600,
                        marginBottom: 100,
                        backgroundColor: "azure",
                        padding: 20,
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                      }}
                    >
                      <div
                        class="input-group-prepend"
                        onChange={(value) => {
                          setCountry(value.target.value);
                        }}
                      >
                        <select className="form-control rounded-o">
                          <option selected>+91</option>
                          <option>+1</option>
                        </select>
                      </div>
                      <input
                        style={{
                          paddingLeft: 20,
                        }}
                        type="tel"
                        class="form-control"
                        onChange={(value) => {
                          setPhone(value.target.value);
                        }}
                        placeholder="Enter your Phone No"
                        aria-label="Enter your Phone No"
                      />
                      <div class="input-group-append">
                        {process ? (
                          <button className="btn btn-success text-white rounded-0">
                            Wait..
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              sendSMS();
                            }}
                            className="btn btn-primary rounded-0"
                          >
                            Call Me
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div>
                    <p className="fz-18">
                      <h3>
                        <a href="tel:+916001374722">+91 60013 74722</a>
                      </h3>
                      <h3 className="mt-3">1800-891-6355 (Tollfree India)</h3>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Specialization</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      So far we have delivered 100+ Ecommerce projects and
                      counting on. Also powering 100s of businesses to achieve
                      millions of users
                    </p>
                    <p className="fz-18">
                      <ol>
                        <li>Supar Fast Software</li>
                        <li>Register Unlimited Users</li>
                        <li>Import Unlimited Products</li>
                        <li>Native Android Application for Free</li>
                        <li>100% Bug Free Delivery</li>
                        <li>Lifetime Customer Support</li>
                      </ol>
                    </p>
                  </div>

                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.125rem" }}
                  ></div>
                  <div className="row">
                    <EcomPricing />
                  </div>
                  <h4 className="mt-3" style={{ fontWeight: 400 }}>
                    Do you want more efficient or enterprise level ecommerce ?
                    Give us a call
                  </h4>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Skills</h3>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem" }}
                  ></div>
                  <div className="ptf-skill-box-grid">
                    <Skills />
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Approach</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      This is how we handle your Ecommerce project order. Our
                      systematic approach helps us to understand your
                      requirement and help us to complete your project
                      efficiently.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Why Us ?</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
