import React from "react";
import { Helmet } from "react-helmet";
import HeaderStudio from "../../components/header/HeaderStudio";
import ServiceList from "../../components/list/ServiceList";
import PortfolioThree from "../../components/portfolio/PortfolioThree";
import Award from "../../components/award/Award";
import Blog from "../../components/blog/Blog";
import FooterThree from "../../components/footer/FooterThree";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";
import ContactJarallax from "../../components/jarallax/ContactJarallax";
import { Link } from "react-router-dom";

const HomeStudio = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-studio">
      <Helmet>
        <title>Broken Pie - The Software Company</title>
        <meta content="We are a software company from India working in the field of Software & Game Development, Blockchain, Robotics and AI." name="description" />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderStudio />
      {/* End Header Agency */}

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-studio">
            {/*=============================================
            Start Service Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem", "--ptf-md": "1.5625rem" }}
              ></div>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {/* <!--Services List--> */}
                  <ul
                    className="ptf-services-list ptf-services-list--style-3"
                    style={{
                      "--ptf-font-family": "var(--ptf-primary-font)",
                      maxWidth: "43.75rem",
                    }}
                  >
                    <ServiceList />
                  </ul>
                </div>
                {/* End <!--Animated Block--> */}

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                >
                  <h1 className="fz-120 fz-90--lg fz-60--md lh-1p1">
                    Blockchain & <br />
                    Software Engineering
                  </h1>
                </div>
                <div className="mt-5">
                  <Link to={'/explore-blockchain'} style={{
                    borderWidth: 2,
                    borderColor: '#4A0EEB',
                    backgroundColor: '#4A0EEB',
                    color: '#fff',
                    borderStyle: 'solid',
                    padding: 10,
                    marginLeft: 5,
                    display: 'inline-block',
                    marginTop: 5,
                    borderRadius: 4
                  }}>Explore Blockchain</Link>
                  <Link to={'/explore-software'} style={{
                    borderWidth: 2,
                    borderColor: '#000',
                    backgroundColor: '#070908',
                    color: '#fff',
                    borderStyle: 'solid',
                    padding: 10,
                    marginLeft: 5,
                    lineBreak: 'strict',
                    display: 'inline-block',
                    marginTop: 5,
                    borderRadius: 4
                  }}>Software Development</Link>
                  <Link to={'/game-development'} style={{
                    borderWidth: 2,
                    borderColor: '#ff8f00',
                    backgroundColor: '#ff8f00',
                    color: '#fff',
                    borderStyle: 'solid',
                    padding: 10,
                    marginLeft: 5,
                    lineBreak: 'strict',
                    display: 'inline-block',
                    marginTop: 5,
                    borderRadius: 4
                  }}>Game Development</Link>
                </div>
                <br />
                <small style={{
                  color: '#555',
                }}> &uarr; click the buttons above</small>
              </div>
            </section>

            {/*=============================================
            Start Service Section 
            ============================================== */}
            <section>
              <div className="container-xxl">
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem" }}
                ></div>
                <div className="video-responsive">
                  <iframe
                    width="853"
                    height="480"
                    src={`https://player.vimeo.com/video/805558588?h=11f5e4161b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Know Broken Pie"
                  />
                </div>
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h5 mt-5 d-inline-flex">Important Case Studies</h2>
                  {/* <Link
                    className="ptf-link-with-arrow fz-14 d-none d-lg-inline-flex"
                    to="/works"
                    style={{ marginLeft: "3.125rem" }}
                  >
                    Case Studies <i className="lnil lnil-chevron-right"></i>
                  </Link> */}
                </div>
                {/* End .ptf-animated-block */}

                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>

                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div
                    className="ptf-isotope-grid row"
                    style={{
                      "--bs-gutter-x": "4.75rem",
                      "--bs-gutter-y": "4.75rem",
                    }}
                  >
                    <PortfolioThree />
                  </div>
                </div>
              </div>
            </section>

            {/*=============================================
            Start Award Section 
            ============================================== */}
            <section className="ptf-custom--1562">
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h5">Broken Pie's Achievements</h2>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>
              </div>
              <div className="container-xxl">
                <Award />
              </div>
            </section>

            {/*=============================================
            Start Blog Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2 className="h5 d-inline-flex">The World of Broken Pie</h2>
                  <a
                    className="ptf-link-with-arrow fz-14 d-none d-lg-inline-flex"
                    href="https://blog.brokenpie.com"
                    target={'_blank'}
                    style={{ marginLeft: "3.125rem" }}
                  >
                    All Articles <i className="lnil lnil-chevron-right"></i>
                  </a>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                ></div>
              </div>
              <div className="container-xxl">
                {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div
                    className="row ptf-isotope-grid"
                    style={{
                      "--bs-gutter-x": "4.75rem",
                      "--bs-gutter-y": "4.75rem",
                    }}
                  >
                    <Blog />
                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>

            {/*=============================================
            Start Contact Section 
            ============================================== */}
            <section
              className="jarallax jarallax-img"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL +
                  "assets/img/services-call-to-action-background.png"
                  })`,
              }}
            >
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": " 12.5rem", "--ptf-md": "6.25rem" }}
              ></div>
              <ContactJarallax />
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div>
      {/* End .ptf-site-wrapper__inner */}

      {/*=============================================
        Start Footer Section 
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div>
  );
};

export default HomeStudio;
