import React from "react";
import { Link } from "react-router-dom";

const serviceContent = [
  {
    icon: "lnil lnil-star",
    titlePart1: "Inauguration",
    titlePart2: "2020",
    descriptions: `We have started our company with the Ministry of Corporate Affairs, India with Registration No: U72900AS2020PTC020515 and with just 5 employees.`,
  },
  {
    icon: "lnil lnil-star",
    titlePart1: "Growth",
    titlePart2: "2021",
    descriptions: `Despite Covid Pandemic, We manage to grow to 50+ engineers and offices in 3 locations (Assam, Tamilnadu and Dubai). Focused on Block chain and AI based projects.`,
  },
  {
    icon: "lnil lnil-star",
    titlePart1: "Continuity",
    titlePart2: "2022",
    descriptions: `Got NASSCOM and International Chambers of Commerce Membership. Developed few great NFT and App based projects with few great orders in hand.`,
  },
  {
    icon: "lnil lnil-graph",
    titlePart1: "Future",
    titlePart2: "",
    descriptions: `We have a plan to grow up to 500+ engineers in coming 3 years and complete a milestone of 1 billion active users of the projects developed by us.`,
  },
];

const ServiceOne = () => {
  return (
    <>
      {serviceContent.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          {/* <!--Services Box--> */}
          <div className="ptf-service-box">
            <div className="ptf-service-box__icon">
              <i className={val.icon}></i>
            </div>
            <h5 className="ptf-service-box__title">
              {val.titlePart1} <br />
              {val.titlePart2}
            </h5>
            <div className="ptf-service-box__content">
              <p>{val.descriptions}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServiceOne;
