import React from "react";
import { Helmet } from "react-helmet";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import HeaderDefault from "../../../components/header/HeaderDefault";

const AiRobotics = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>Future of AI & Robotics in coming 50 years ?</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">
                  AI & Robotics in 50 years ?
                </h1>
                <div className="ptf-single-post__meta">
                  <span className="cat">By</span>
                  <span className="date">Dr. Fahmida Yeasmin for Broken Pie</span>
                </div>
              </div>
            </header>


            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    {/* <!--Post Info--> */}
                    <div className="ptf-single-post__info">
                      <a className="author" href="#">
                        <i className="lnil lnil-user"></i>by <span>Dr. Fahmida Nishat Yeasmin</span>
                      </a>
                      <a className="view" href="#">
                        <i className="lnil lnil-eye"></i>1.2k+ Views
                      </a>
                      <a className="comments" href="#">
                        <i className="lnil lnil-comments"></i>0 Comments
                      </a>
                      <a className="report" href="#">
                        <i className="lnil lnil-warning"></i>Report
                      </a>
                    </div>

                    {/* <!--Post Excerpt--> */}
                    <div className="ptf-single-post__excerpt">
                      With all the capabilities already embedded in AI based robots, it is a visionary dream that robots
                      will be able to use
                      <span className="has-accent-1"> machine learning </span>to develop more complex solutions to situations on their
                      own.
                    </div>

                    {/* <!--Post Content--> */}
                    <div className="ptf-single-post__content">
                      <p>
                        There was once a time when the word robots was confined to machines doing chores that are
                        hazardous to human beings or simple tasks which could make human life easier. The field of
                        robotics worked on the design and construction of machines which are programmed to follow
                        commands while receiving step by step signal or guidance through gadgets embedded in their
                        environment. But the future of robotics is in artificial intelligence which allows them to operate
                        without any external intervention or pre-programmed instructions.<br /><br />
                        The combination of AI and robotics holds potential for futuristic developments. With the help of
                        machine learning robots are able to teach them gazillion ways to handle situations they haven’t
                        faced before. When it comes to mobility, traditional robots faced difficulty whenever there would
                        be an obstacle on the paths but AI fitted robots on the other hand would create real-time maps
                        to outcome the obstacle. AI robots are already being used in industries and factories for
                        exploring environments that are dangerous for humans.<br /><br />
                        One of the most interesting aspects of AI and robotics are the humanoid robots. They have
                        body shape and features similar to humans. They are mostly used to space research, human
                        assistance, healthcare facilities, education etc because of their capabilities to program just like
                        humans without any human intervention, with the help of machine learning.<br /><br />
                        Some examples of advanced humanoid robots are “Walker” developed by Ubtech Robotics
                        which has features like precise and stable walking, object and facial recognition, “Romeo” which
                        is capable of walking, facial recognition,holding objects and even human assistance in
                        day-to-day chores, “Zenbo” by Asus is capable of acting as a security manager, kitchen
                        assistant, friendly companion to children and adults by singing songs, telling stories, buying
                        goods online for us and also tutor us.<br /><br />
                        With all the capabilities already embedded in AI based robots, it is a visionary dream that robots
                        will be able to use machine learning to develop more complex solutions to situations on their
                        own. They will be able to handle complex cognitive tasks and think just like humans.
                        Now there arises the most debatable question- Will AI based robots take over humanity?
                        With the advanced AIs integrated in robots and their ability to perform complex tasks, there will
                        be possible job losses in every sector. But we are already equipped with virtual assistants and
                        robots all around us. As we step into the future of AI and robotics where humans and robots
                        work in a shared workplace it is important to understand that although they are created by
                        human brains, they are much more capable and intelligent than an average human being. They
                        serve as a companion from starting with our kitchen to factories and industries. They can do
                        tasks like sing a lullaby for us to handle radioactive materials. The future is AI and it will keep
                        getting elevated every day, far more than our imagination.
                      </p>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "5rem", "--ptf-md": "2.5rem" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AiRobotics;
