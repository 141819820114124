import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Is the cost final ?",
    desc: `Of course not, Negotiation is possible (not on the base price). However, we offer the lowest cost in the global market. వాస్తవానికి కాదు, చర్చలు సాధ్యమే. అయితే, మేము ప్రపంచ మార్కెట్‌లో అతి తక్కువ ధరను అందిస్తున్నాము.`,
    expand: "a",
  },
  {
    title: "How about payment?",
    desc: `For each project, generally 50% payment has to be made in advance against the quotation. However, negotiation is possible if the quotation value is above 50,000 INR. Please contact us in advance. ప్రతి ప్రాజెక్ట్ కోసం, సాధారణంగా కొటేషన్‌కు వ్యతిరేకంగా 50% చెల్లింపును ముందుగానే చెల్లించాలి. అయితే, చర్చలు సాధ్యమే. దయచేసి ముందుగా మమ్మల్ని సంప్రదించండి.`,
    expand: "b",
  },
  {
    title: "How long support, that I get?",
    desc: `After Sales support (error & bug fixes) is available for lifetime without any cost. However, general & security maintenance is just for 1 year from the date of delivery of your project. సాంకేతిక మద్దతు (ఎర్రర్ & బగ్ పరిష్కారాలు) ఎటువంటి ఖర్చు లేకుండా జీవితకాలం అందుబాటులో ఉంటుంది. అయితే, సాధారణ & భద్రతా నిర్వహణ మీ ప్రాజెక్ట్ డెలివరీ తేదీ నుండి కేవలం 1 సంవత్సరం మాత్రమే.`,
    expand: "c",
  },
  {
    title: "If I am not satisfied with your service, can I get a refund ?",
    desc: `Of course you'll get a full refund if we do not deliver the project we have promised (written promise will be given along with the quotation). మేము వాగ్దానం చేసిన ప్రాజెక్ట్‌ను అందించకపోతే మీరు పూర్తి వాపసు పొందుతారు (కొటేషన్‌తో పాటు వ్రాతపూర్వక వాగ్దానం కూడా ఇవ్వబడుతుంది).`,
    expand: "d",
  },
  {
    title: "Do you provide NDA Agreement?",
    desc: `There will be a Non Disclosure Agreement with every project. NDA agreement will be provided along with the quotation. ప్రతి ప్రాజెక్ట్‌తో నాన్ డిస్‌క్లోజర్ అగ్రిమెంట్ ఉంటుంది. కొటేషన్‌తో పాటు ఎన్‌డిఎ ఒప్పందం కూడా అందించబడుతుంది. Download NDA Here : <a href="https://bit.ly/43aTmek" target="_blank">https://bit.ly/43aTmek</a>`,
    expand: "e",
  },
];

const Faq = () => {
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd> <div dangerouslySetInnerHTML={{ __html: item.desc }}></div></dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq;
