import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import Approach3 from "../../../../components/service/Approach3";
import 'react-awesome-slider/dist/styles.css';
import Pricing from "../../../../components/service/Pricing";
import SlackForm from "../../../../components/service/SlackForm";
import { salesemail, softwarephone } from "../../../../Const";

export default function ExploreSoftware() {

  return (
    <div>
      <Helmet>
        <title>Software & Mobile Application Development</title>
        <meta name="content" description="Software and mobile application development will continue to evolve and become more automated, productive, and secure." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main m-2">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            <div className="container-xxl mt-5">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Software <strong>& App</strong> development

                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      Software and mobile application development will continue to evolve and become more automated, productive, and secure which will generate more revenue in business, reduce human workload and make business more profitable. At Broken Pie, we don't just follow the norm; we reshape it, breaking the conventional "pie" to create groundbreaking digital solutions that set you apart
                    </p>
                  </div>
                  {/* <!--Spacer--> */}

                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="h5">What can be done by us:</h2>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/1.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/2.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/3.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/4.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/5.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/software/6.png" />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h4 style={{
                    fontWeight: 600
                  }}>How software can help businesses to grow ?</h4>
                </div>
                <div className="col-12" style={{
                  color: '#000',
                  textAlign: 'justify',
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: 15,
                  fontSize: 20,
                  backgroundColor: '#fff',
                  boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                  borderRadius: 10,
                }}>
                  <p><strong>Improving productivity:</strong> Software can automate repetitive tasks and streamline workflows, freeing up employees' time to focus on more strategic and higher value activities. This can increase productivity, allowing businesses to grow without necessarily having to hire more staff.</p>

                  <p><strong>Scaling operations:</strong> As businesses grow, they may need to scale their operations quickly to meet demand. Software can help businesses automate and standardize processes, allowing them to scale more efficiently and cost-effectively.</p>

                  <p><strong>Enhancing customer experience:</strong> Software can help businesses provide better customer service by offering self-service options, personalized experiences, and real-time support. This can help businesses retain existing customers and attract new ones, contributing to growth.</p>

                  <p><strong>Expanding reach:</strong> Software can help businesses expand their reach by offering online sales channels, social media marketing, and other digital marketing strategies. This allows businesses to reach new customers and grow their market share.</p>                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <div className="row">
                  <div className="col-sm-4">
                    <h3 style={{
                      borderColor: '#ccc',
                      borderWidth: 5,
                      borderStyle: 'solid',
                      padding: 10,
                      marginBottom: 50
                    }}>
                      <p>
                        Call Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"tel:" + softwarephone}>{softwarephone}</a>
                      </p>
                      <p>
                        Email Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"mailto:" + salesemail}>{salesemail}</a>
                      </p>
                    </h3>
                  </div>
                  <div className="col-sm-8" style={{
                    padding: 10,
                    borderWidth: 5,
                    borderStyle: 'solid',
                    borderColor: '#dfdfdf',
                    borderRadius: 10,
                    margin: 'auto',
                    alignSelf: 'center',
                    backgroundColor: '#fff'
                  }}>
                    <img src="/assets/software/card.png" className="img-fluid" />
                  </div>
                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <blockquote style={{
                  borderLeftColor: 'purple',
                  borderLeftWidth: 10,
                  borderLeftStyle: 'solid',
                  fontWeight: 200
                }}>
                  The Global Software market was valued at approximately 540 billion U.S. dollars in 2020 and is projected to grow to over 800 billion U.S. dollars by 2026. As per research, by the year 2030, AI, machine learning, and data science will affect all spheres, and software code will automate most jobs worldwide

                </blockquote>
              </div>
              <div className="col-12">
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.875rem" }}
                ></div>
                <Pricing />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "2rem" }}
                ></div>
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h3>Whats Next ?</h3>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                </div>
                <Approach3 />
              </div>
              <div className="row">

                <div className="col-sm-6">
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                  <SlackForm />
                </div>
                <div className="col-sm-6">
                  <a href="https://blog.brokenpie.com/future-of-information-technology" target={'_blank'}>
                    <img alt="Software Blog" src="/assets/software/blog.png" style={{
                      maxWidth: 400,
                      width: '100%',
                      margin: 'auto',
                      borderRadius: 10,
                      marginTop: 20
                    }} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
