import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import Footer from "../../../components/footer/Footer";

const SnogCab = () => {
  return (
    <div>
      <Helmet>
        <title>Snog Cab - Blockchain based cab aggregator</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page">
          <section className="min-vh-100">
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">

                <div className="col-lg-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h4 className="large-heading">
                      <strong>Snog Cab</strong> -
                      A Case Study
                    </h4>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem", "--ptf-md": "1.875rem" }}
                  ></div>
                  <blockquote style={{
                    borderLeft: `5px solid purple`,
                    paddingLeft: 10,
                    fontWeight: 300
                  }}>
                    It is first in South Asia to adopt blockchain technology in a cab aggregator business. Our challange was manage gas fee and technology fee within a tight budget as Snog was taking only few percentage from every ride.
                  </blockquote>

                </div>
                <div className="col-lg-3 text-center">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <img
                      style={{ maxWidth: 200 }}
                      src="https://i.imgur.com/71U9twm.png"
                      alt="snog"
                      loading="lazy"
                    />
                  </div>

                </div>
                <div className="col-12" style={{
                  fontSize: 20
                }}>
                  <p><strong>Introduction:</strong> Snog is a blockchain-based cab aggregator developed by Broken Pie, which aims to provide a secure, efficient, and cost-effective platform for ride-sharing. The platform is designed to operate on a decentralized network, powered by blockchain technology, and aims to address the challenges faced by traditional ride-sharing platforms such as high fees, lack of transparency, and low security.</p>
                  <p><strong>Challenge:</strong> One of the key challenges faced by the team during the development of Snog was managing the blockchain gas fee and technology fee within a tight budget. As Snog was taking only a few percentages from every ride, it was crucial to ensure that the platform's operating costs remained low, while still delivering a seamless and secure ride-sharing experience for its users.</p>
                  <p><strong>Solution:</strong> To address this challenge, the team at Broken Pie adopted a multi-pronged approach that involved optimizing the platform's architecture and leveraging cutting-edge blockchain technology. They also partnered with leading blockchain service providers to help manage the blockchain gas fee and technology fee.</p>
                  <p><strong>Architecture optimization:</strong> The Snog platform was designed with a modular architecture that allowed for easy integration with different blockchain networks. This approach helped the team to keep the platform's operating costs low, as they could leverage the benefits of different blockchain networks, such as low transaction fees and high security, without incurring high development costs.</p>
                  <p><strong>Leveraging cutting-edge blockchain technology:</strong> The team also leveraged cutting-edge blockchain technology, such as sharding and plasma, to help reduce the platform's operating costs. Sharding, for example, helped to improve the platform's scalability by allowing for parallel processing of transactions, while plasma helped to reduce the number of on-chain transactions, thereby lowering the gas fee.</p>
                  <p><strong>Partnership with blockchain service providers:</strong> To further reduce the platform's operating costs, the team partnered with leading blockchain service providers, such as Infura and Chainlink, to help manage the blockchain gas fee and technology fee. Infura, for example, provided the team with a reliable and cost-effective way to access the Ethereum blockchain, while Chainlink helped to ensure that the platform's smart contracts were executed accurately and efficiently.</p>
                  <p><strong>Outcome:</strong> By adopting this multi-pronged approach, the team at Broken Pie was able to successfully manage the blockchain gas fee and technology fee within a tight budget, while still delivering a secure, efficient, and cost-effective ride-sharing platform. The Snog platform has since gained popularity among users, and the team continues to explore new ways to optimize the platform's architecture and leverage cutting-edge blockchain technology to further reduce operating costs and enhance the user experience.</p>
                  <h5 style={{ marginTop: 10, fontWeight: 400 }}>Know more at <a target={'_blank'} href="https://snogweb.com">www.snogweb.com</a></h5>
                </div>
              </div>

            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "14.375rem", "--ptf-md": "7.1875rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SnogCab;
