import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import { Link } from "react-router-dom";

const EcommerceSoftwareFeatures = () => {
  return (
    <div>
      <Helmet>
        <title>Ecommerce Software Features</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <Link to={"/mlm-software"}>
                      <a className="btn btn-warning mb-5">&larr; Go Back</a>
                    </Link>
                    <h1 className="large-heading">
                      Ecommerce Software Features
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      These all are universal features, we may add or remove
                      more features in your project as per your requirements.{" "}
                      <br />
                      ఇవన్నీ సార్వత్రిక లక్షణాలు, మీ అవసరాలకు అనుగుణంగా మేము మీ
                      ప్రాజెక్ట్‌లో మరిన్ని ఫీచర్‌లను జోడించవచ్చు లేదా
                      తీసివేయవచ్చు
                      <br />
                      Dit is almal universele kenmerke, ons kan meer kenmerke in
                      u projek byvoeg of verwyder volgens u vereistes
                      <hr />
                      <Link to={"/contact"}>
                        <a className="btn btn-warning">Contact Us</a>
                      </Link>{" "}
                      &nbsp;
                      <Link to={"/ecommerce-software-demo"}>
                        <a className="btn btn-primary">
                          Ecommerce Software Demos
                        </a>
                      </Link>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12">
                  <div className="row mb-3">
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-light text-dark fw-bold">
                          Universal Features
                        </div>
                        <div class="card-body">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">Unlimited Customers</li>
                            <li class="list-group-item">Unlimited SKUs</li>
                            <li class="list-group-item">
                              6 Integrated Payment Gateways
                            </li>
                            <li class="list-group-item">Unlimited Products</li>
                            <li class="list-group-item">
                              Digital / Downloadable Products
                            </li>
                            <li class="list-group-item">
                              Distributor / Franchisee Program
                            </li>

                            <li class="list-group-item">
                              Staff, Payroll Management
                            </li>

                            <li class="list-group-item">
                              Integrated simple GST Billing
                            </li>

                            <li class="list-group-item">
                              Product & Stock Management
                            </li>

                            <li class="list-group-item">
                              CMS / Self Managable Website
                            </li>

                            <li class="list-group-item">
                              7th Level Hack Tested secure software and server
                            </li>

                            <li class="list-group-item">
                              Handles millions of users from day 1
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-light text-dark fw-bold">
                          Admin Features
                        </div>
                        <div class="card-body">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">Customer Management</li>
                            <li class="list-group-item">
                              Fund & Wallet Management
                            </li>
                            <li class="list-group-item">Shipping & Logistic</li>
                            <li class="list-group-item">
                              Transaction Management
                            </li>
                            <li class="list-group-item">Date wise reporting</li>
                            <li class="list-group-item">
                              Export to Excel Sheet / PDF
                            </li>
                            <li class="list-group-item">
                              Multicurrency / Multivendor management
                            </li>
                            <li class="list-group-item">Product Management</li>
                            <li class="list-group-item">
                              Franchisee Management
                            </li>
                            <li class="list-group-item">
                              Staff & Salary Management
                            </li>
                            <li class="list-group-item">Full Accounting</li>
                            <li class="list-group-item">
                              Stock & Invoice Management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-light text-dark fw-bold">
                          Customer Features
                        </div>
                        <div class="card-body">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item">Order history</li>
                            <li class="list-group-item">Cart and Wishlist</li>
                            <li class="list-group-item">Refund Management</li>
                            <li class="list-group-item">re Order system</li>
                            <li class="list-group-item">Withdraw Earning</li>
                            <li class="list-group-item">
                              General Ecommerce Features
                            </li>

                            <li class="list-group-item">
                              Support Request and etc.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-1">
                      <div className="card">
                        <div class="card-header bg-light text-dark fw-bold">
                          More Features
                        </div>
                        <div class="card-body">
                          <div class="list-group">
                            <a
                              href="#"
                              class="list-group-item list-group-item-action active"
                              aria-current="true"
                            >
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 text-white">
                                  Open Source Code
                                </h5>
                              </div>
                              <p class="mb-1">
                                You can fully customize it in one domain.{" "}
                              </p>
                            </a>

                            <a
                              href="#"
                              class="list-group-item list-group-item-action"
                            >
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Free Mobile Apps</h5>
                              </div>
                              <p class="mb-1">
                                We are offering free Highest Quality (native)
                                app with each project.
                              </p>
                              <small class="text-muted">Android, iOS</small>
                            </a>
                            <a
                              href="#"
                              class="list-group-item list-group-item-action"
                            >
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">Server & Domain Free</h5>
                              </div>
                              <p class="mb-1">
                                We are offering free domain & server for 1 year.
                              </p>
                              <small class="text-muted">Optional</small>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EcommerceSoftwareFeatures;
