import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import { Link } from "react-router-dom";
import Card from "../Card";
import SlackForm from "../../../../components/service/SlackForm";

const MLMSoftwareDemo = () => {
  return (
    <div>
      <Helmet>
        <title>MLM Software Demos</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <Link to={"/mlm-software"}>
                      <a className="btn btn-warning mb-5">&larr; Go Back</a>
                    </Link>
                    <h1 className="large-heading">MLM Demos</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      These all are just demo, we'll customize your plan as per
                      your requirements. <br />
                      ఇవన్నీ కేవలం డెమో మాత్రమే, మేము మీ అవసరాలకు అనుగుణంగా మీ
                      ప్లాన్‌ని అనుకూలీకరిస్తాము.
                      <br />
                      Dit is alles net demonstrasies, ons sal jou plan aanpas
                      volgens jou vereistes.
                      <hr />
                      <Link to={"/contact"}>
                        <a className="btn btn-warning">Contact Us</a>
                      </Link>{" "}
                      &nbsp;
                      <Link to={"/mlm-software-features"}>
                        <a className="btn btn-primary">MLM Software Features</a>
                      </Link>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-sm-4">
                  <Card
                    title={"MLM Software Demos"}
                    subtitle={"All MLM Software Demos Without Smart Contract"}
                    content={
                      <div>
                        <a
                          href="https://bpiedemo.biz/mlm-demos.html"
                          target={"_blank"}
                          style={{
                            width: 200,
                          }}
                          className="btn btn-warning btn-lg"
                        >
                         Open Demos &rarr;
                        </a>
                      </div>
                    }
                  />
                </div>
                <div className="col-sm-4">
                  <Card
                    title={"Decentralized Plan Demo (dApp)"}
                    subtitle={"With Smart Contract"}
                    content={
                      <div>
                        <a
                          href="https://bpiedemo.biz/crypto/access/staff"
                          style={{
                            width: 200,
                          }}
                          target={"_blank"}
                          className="btn btn-warning btn-lg"
                        >
                          Admin Panel
                        </a>
                        <br />
                        <a
                          href="https://bpiedemo.biz/crypto/access/member"
                          style={{
                            width: 200,
                          }}
                          target={"_blank"}
                          className="btn btn-warning btn-lg mt-3"
                        >
                          Member Panel
                        </a>
                        <br />
                        <a
                          href="https://bpiedemo.biz/crypto/access/register"
                          style={{
                            width: 200,
                          }}
                          target={"_blank"}
                          className="btn btn-warning btn-lg mt-3"
                        >
                          Register
                        </a>
                      </div>
                    }
                  />
                </div>
                <div className="col-sm-4">
                  <Card
                    title={"Application Demo"}
                    subtitle={"Android Apps Demo"}
                    content={
                      <div>
                        <a
                          style={{
                            width: 200,
                          }}
                          href="https://play.google.com/store/apps/details?id=app.cashkamaalo.in"
                          target={"_blank"}
                          className="btn btn-info btn-lg"
                        >
                          Native Application
                        </a>
                        <br />
                        <br />
                        <a
                          style={{
                            width: 200,
                          }}
                          href="/free.apk"
                          target={"_blank"}
                          className="btn btn-warning btn-lg"
                        >
                          Free Application
                        </a>
                        <br />
                      </div>
                    }
                  />
                </div>
                <blockquote
                  style={{
                    borderLeft: "10px solid #04016A",
                    backgroundColor: "#f5f5f5",
                    padding: "20px",
                    margin: "20px 0",
                    fontStyle: "italic",
                    lineHeight: "1.5",
                    fontWeight: 500,
                    marginTop: 100,
                  }}
                >
                  <p>
                    We offer free hybrid android application (webview) with all
                    MLM Softwares, However Native application is not free. We
                    can build Android & iOS native application at lowest cost in
                    the world market.
                  </p>
                </blockquote>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "2.5rem" }}
                ></div>
                <h3 className="mt-4 text-center">
                  <ul>MLM Software Demo Videos</ul>
                </h3>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="m-5">
                      <iframe
                        src="https://player.vimeo.com/video/737519168?h=8fd9c40e19&loop=1&byline=0&portrait=0"
                        width="100%"
                        height="360"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="m-5">
                      <iframe
                        src="https://player.vimeo.com/video/805227991?h=53c9ae6e67&loop=1&byline=0&portrait=0"
                        width="100%"
                        height="360"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.5rem" }}
              ></div>
              <SlackForm />
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.5rem" }}
              ></div>
            </div>
          </section>
          {/* Top Portion */}

          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MLMSoftwareDemo;
