import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import Footer from "../../components/footer/Footer";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";

const Media = () => {
  return (
    <div>
      <Helmet>
        <title>Broken Pie - Media</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--pricing">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">Media</h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-24">
                      For Media enquiries: media@brokenpie.com
                      <h4 className="mb-4">We have been mentioned in: </h4>
                      <strong>
                        <a
                          target={"_blank"}
                          style={{ backgroundColor: "#1F0950" }}
                          className="btn btn-dark btn-lg text-white"
                          href="https://www.benzinga.com/pressreleases/22/04/ab26581868/broken-pie-is-now-offering-blockchaincryptocurrency-development-consultation-services-at-no-cost"
                        >
                          Benzinga
                        </a>
                      </strong>
                      &nbsp;
                      <strong>
                        <a
                          target={"_blank"}
                          style={{ backgroundColor: "#1F0950" }}
                          className="btn btn-dark btn-lg text-white"
                          href="https://fox8.com/business/press-releases/ein-presswire/620327343/broken-pie-to-launch-saga-reserve-a-p2p-payment-gateway-service-built-on-its-own-blockchain/"
                        >
                          Fox 8
                        </a>
                      </strong>
                      {/* &nbsp;<strong><a target={"_blank"} style={{ backgroundColor: '#FA4529' }} className="btn btn-danger text-white" href="https://www.benzinga.com/pressreleases/22/04/ab26581868/broken-pie-is-now-offering-blockchaincryptocurrency-development-consultation-services-at-no-cost">Benzinga &rarr;</a></strong> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10"></div>
              </div>
            </div>{" "}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End pricing top section title */}
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Media;
