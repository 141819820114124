import React from 'react';

const TagCloud = ({ tags }) => {
    const fontSizeMin = 15;
    const fontSizeMax = 30;
    
    // Calculate the minimum and maximum tag count
    const minCount = Math.min(...tags.map(tag => tag.count));
    const maxCount = Math.max(...tags.map(tag => tag.count));

    // Function to scale tag count to font size
    const computeFontSize = count => {
        if(maxCount === minCount) {
            return (fontSizeMax + fontSizeMin) / 2;
        }
        return ((count - minCount) / (maxCount - minCount)) * (fontSizeMax - fontSizeMin) + fontSizeMin;
    };

    const containerStyle = {
        border: '6px solid #ccc',
        borderRadius: '10px',
        padding: '20px',
        margin: '10px',
        textAlign: 'center'
    };

    return (
        <div style={containerStyle}>
        <div className="tag-cloud">
            {tags.map(tag => (
                <span 
                    key={tag.value}
                    style={{
                        fontSize: `${computeFontSize(tag.count)}px`,
                    }}
                >
                    {tag.value}
                </span>
            ))}
        </div>
        </div>
    );
};

export default TagCloud;
