import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import Approach3 from "../../../../components/service/Approach3";
import 'react-awesome-slider/dist/styles.css';
import Pricing from "../../../../components/service/Pricing";
import SlackForm from "../../../../components/service/SlackForm";
import { gamephone, salesemail } from "../../../../Const";

export default function ExploreGame() {

  return (
    <div>
      <Helmet>
        <title>Game Development - Broken Pie</title>
        <meta name="content" description="Software development will continue to evolve and become more automated, productive, and secure." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main m-2">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            <div className="container-xxl mt-5">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Develop your <strong> Dream Game</strong> at lowest cost !

                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      Discover the future of game development with Broken Pie. As a leading software company, we provide unparalleled game development services tailored to your unique vision. We turn your ideas into immersive gaming experiences. Whether you’re an individual looking to create your first game or a corporate entity seeking to scale your existing portfolio, Broken Pie is your go-to partner for excellence in game development. We're not just developers; we're dream enablers who turn your creative concepts into playable realities. Trust Broken Pie, where your gaming dreams take flight.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}

                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="h5">What can be done by us:</h2>
                </div>
                <div className="col-lg-2 col-sm-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/1.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/2.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/3.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/4.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/5.png" />
                </div>
                <div className="col-lg-2 col-md-3">
                  <img style={{
                    borderRadius: 5,
                    marginTop: 5
                  }} src="/assets/game/6.png" />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-12 mb-4">
                  <h4 style={{
                    fontWeight: 600
                  }}>Is game development profitable ?</h4>
                </div>
                <div className="col-12" style={{
                  color: '#000',
                  textAlign: 'justify',
                  borderColor: '#ccc',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: 15,
                  fontSize: 20,
                  backgroundColor: '#fff',
                  boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                  borderRadius: 10,
                }}>
                  <p>
                    Absolutely, game development can be highly profitable! The global gaming industry is booming, and there has never been a better time to bring your vision to life. At Broken Pie, we offer game development services that not only translate your ideas into compelling gaming experiences but also ensure they are market-ready and have the potential for high returns. Our expert team is well-versed in the latest monetization strategies, from in-app purchases and premium upgrades to ad integration and subscription models. With Broken Pie, you aren't just creating a game, you're building a sustainable revenue stream. Let's work together to create games that players love and that contribute positively to your bottom line.
                  </p>
                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <div className="row">
                  <div className="col-sm-4">
                    <h3 style={{
                      borderColor: '#ccc',
                      borderWidth: 5,
                      borderStyle: 'solid',
                      padding: 10,
                      marginBottom: 50
                    }}>
                      <p>
                        Call Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"tel:" + gamephone}>{gamephone}</a>
                      </p>
                      <p>
                        Email Us :<br /> <a style={{
                          fontWeight: 300,
                          fontSize: 24
                        }} href={"mailto:" + salesemail}>{salesemail}</a>
                      </p>
                    </h3>
                  </div>
                  <div className="col-sm-8" style={{
                    padding: 10,
                    borderWidth: 5,
                    borderStyle: 'solid',
                    borderColor: '#dfdfdf',
                    borderRadius: 10,
                    margin: 'auto',
                    alignSelf: 'center',
                    backgroundColor: '#fff'
                  }}>
                    <img src="/assets/game/card.png" className="img-fluid" />
                  </div>
                </div>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
                ></div>
                <blockquote style={{
                  borderLeftColor: 'purple',
                  borderLeftWidth: 10,
                  borderLeftStyle: 'solid',
                  fontWeight: 200
                }}>
                  numerous studies and reports have demonstrated the popularity of video games across various demographics worldwide. For example, the Entertainment Software Association (ESA) published a report in 2021 stating that 75% of American households had at least one person who played video games.

                  In terms of specific game genres, the most popular tend to be Action and Shooter games, Role-Playing Games (RPGs), and Sports games, though this can vary by region and platform. <strong>Research firm Newzoo has predicted the global games market to reach $189.3 billion in revenue by the end of 2021, with a CAGR of 10.5% from 2020 to 2023, showing the industry's consistent growth.</strong>

                </blockquote>
              </div>
              <div className="col-12">
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.875rem" }}
                ></div>
                <Pricing />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "2rem" }}
                ></div>
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h3>Whats Next ?</h3>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                </div>
                <Approach3 />
              </div>
              <div className="row">

                <div className="col-sm-6">
                  <div
                    className="ptf-spacer mt-5"
                    style={{ "--ptf-xxl": "1.875rem" }}
                  ></div>
                  <SlackForm />
                </div>
                <div className="col-sm-6">
                  <a href="https://blog.brokenpie.com/leveraging-game-development-for-success-a-partnership-with-broken-pie" target={'_blank'}>
                    <img alt="Software Blog" src="/assets/game/blog.png" style={{
                      maxWidth: 400,
                      width: '100%',
                      margin: 'auto',
                      borderRadius: 10,
                      marginTop: 20
                    }} className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
