import React from "react";

const serviceContent = [
  { icon: "lnil-cup", name: "Handles Millions of users" },
  { icon: "lnil-website-rank", name: "Fully Opensource" },
  { icon: "lnil-3d", name: "Highly Secure Software" },
  { icon: "lnil-video-camera-alt-1", name: "Dedicated Support" },
];

const ServiceListThree = () => {
  return (
    <>
      <ul>
        {serviceContent.map((val, i) => (
          <li key={i}>
            <a href="#">
              <i className={`lnil ${val.icon}`}></i> {val.name}
            </a>
          </li>
        ))}
      </ul>
      <a href="/bpie-gurranted">
        <img
          src={"/assets/img/root/bpiegur.png"}
          className="img-fluid mt-4"
          style={{ height: 70 }}
        />{" "}
        <br />
        <span style={{ color: "red" }}>what it is ?</span>
      </a>
    </>
  );
};

export default ServiceListThree;
