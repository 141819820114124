import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import ServiceListThree from "../../../../components/list/ServiceListThree";
import Faq from "../../../../components/faq/Faq";
import Skills from "../../../../components/skill/Skills";
import Approach2 from "../../../../components/service/Approach2";
import { Link } from "react-router-dom";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import SlackForm from "../../../../components/service/SlackForm";
import { mlmphone, tollfreeindia, tollfreeusa } from "../../../../Const";
import TagCloud from "./MLMTag";

const tags = [
  { value: 'Network Marketing', count: 20 },
  { value: 'Direct Selling', count: 18 },
  { value: 'Compensation Plan', count: 22 },
  { value: 'Dontaion Plan', count: 15 },
  { value: 'Investment Plan', count: 16 },
  { value: 'Lead Generation Software', count: 24 },
  { value: 'Crypto based Decentralised Plans', count: 18 },
  { value: 'Commission Tracking', count: 20 },
  { value: 'Sales Tracking', count: 17 },
  { value: 'MLM Software', count: 30 },
  { value: 'Affiliate Marketing', count: 21 },
  { value: 'E-commerce Integration', count: 19 },
  { value: 'Binary Plans', count: 22 },
  { value: 'Board Plans', count: 21 },
  { value: 'Generation and Level Plans', count: 19 },
  { value: 'Repurchase & Distributorship', count: 21 },
];


export default function MLMSoftware() {

  return (
    <div>
      <Helmet>
        <title>MLM Software @ 10,000 INR ($ 130 USD)</title>
        <meta name="content" description="We are pioneer in MLM software development with an experience of 1000s of MLM software development works." />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}

            <div className="container-xxl">
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading">
                      Any mlm Software <br />@ ₹ 10,000 INR{" "}
                      <sup style={{ fontSize: 14, color: "green" }}>Starts</sup>
                    </h1>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      Whether it is Binary, Matrix, Uni-level, Smart Contract
                      Based Decentralised plan, Investment or Dontation or Board
                      plan, we cover everything.<br />
                      <hr />

                      <Link to={"/mlm-software-demo"}>
                        <a className="btn btn-dark btn-lg mt-2" style={{
                          backgroundColor: '#771AB9'
                        }}>MLM Software Demo</a>
                      </Link>{" "}
                      &nbsp;
                      <Link to={"/mlm-software-features"}>
                        <a className="btn btn-dark btn-lg mt-2" style={{
                          backgroundColor: '#000'
                        }}>MLM Software Features</a>
                      </Link>
                    </p>
                  </div>
                  <TagCloud tags={tags} />
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12 mb-4" style={{ alignItems: 'center', textAlign: 'center', maxWidth: 900, alignSelf: 'center', margin: 'auto' }}>
                  <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/805583509?h=bc8b98c910&badge=0&autopause=0&player_id=0&app_id=58479" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 20, borderWidth: 5, borderColor: '#314a00', borderStyle: 'solid', boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px` }} title="Broken Pie MLM" frameBorder={0} /></div>
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <AwesomeSlider>
                    <div>
                      <img src="https://i.imgur.com/V2VC7ph.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/qJ0WBuE.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/tapunJy.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/13jEHGP.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/4W308Hd.png" />
                    </div>
                  </AwesomeSlider>
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "1.8125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}

                  <div>
                    <h3 style={{
                      fontWeight: 400,
                      marginBottom: 40,
                      marginTop: 40
                    }}>Call our Sales Team</h3>


                    <p className="fz-18" style={{
                      backgroundColor: '#1E1E1E',
                      padding: 20,
                      borderRadius: 10,
                      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`
                    }}>
                      <h4 style={{
                        fontWeight: 400
                      }}>
                        <a className="text-white" href={'tel:' + mlmphone.replace(/ /g, '')}>{mlmphone}</a><br />
                      </h4>
                      <h4 style={{
                        fontWeight: 400
                      }} className="mt-3">
                        <a className="text-white" href={'tel:' + tollfreeindia}>{tollfreeindia} (Tollfree India)</a>
                        {tollfreeusa !== null && <a href={'tel:' + tollfreeusa}>{tollfreeusa} (Tollfree USA)</a>}
                      </h4>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3 style={{
                      fontWeight: 400
                    }}>Specialization</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      So far we have delivered 2000+ MLM projects and counting
                      on. Also powering 100s of businesses to achieve millions
                      of users
                    </p>
                    <p className="fz-18">
                      <ol>
                        <li>Supar Fast Software</li>
                        <li>Register Unlimited Users</li>
                        <li>Import Unlimited Products</li>
                        <li>Android Application for Free</li>
                        <li>Payment & Payout API integration</li>
                        <li>100% Bug Free Delivery</li>
                        <li>Lifetime Customer Support</li>
                        <li>Any MLM Plan at lowest cost in the world market</li>
                      </ol>
                    </p>
                  </div>
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem" }}
                  ></div>
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Few MLM Clients</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      <div className="row">
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://agrf.net" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            AGRF Network
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://peer2peercommunity.live" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            P2P Community
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://nutrisukan.co.in/" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                           Nutrisukan
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://3zstoken.com" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                           3ZS Token
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://easyachievellp.in" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Easy Achieve
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://palamtraders.com" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Palam Traders
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://brttrade.biz" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            BRT Trade
                          </a>
                        </div>

                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://cashkamaalo.in" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Cash Kamaalo
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://mmgroupss.com" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />M & M Groups
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a
                            href="https://smartonewellness.in"
                            target={"_blank"}
                          >
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Smart One
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://allmanee.com" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Allmanee
                          </a>
                        </div>
                        <div className="col-4 col-sm-3 mt-3">
                          <a href="https://affluencecrypto.io/" target={"_blank"}>
                            <img
                              src="/assets/img/website-design.png"
                              style={{ width: 100, height: 100 }}
                              className="img-fluid"
                            />
                            <br />
                            Affluence Crypto
                          </a>
                        </div>
                      </div>
                    </p>
                  </div>
                  <a href="/apps" className="btn btn-dark btn-lg mt-5">View our Portfolio &rarr;</a>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.125rem" }}
                  ></div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>What we use </h3>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem" }}
                  ></div>
                  <div className="ptf-skill-box-grid">
                    <Skills />
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Approach</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      This is how we handle your MLM project order. Our
                      systematic approach helps us to understand your
                      requirement and help us to complete your project
                      efficiently.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <Approach2 />
                  <SlackForm />
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "2.5rem" }}
                  ></div>
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Why Us ?</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
