import React from "react";
import { Helmet } from "react-helmet";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import HeaderDefault from "../../../components/header/HeaderDefault";

const IsWeb3Myth = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>Is Web3 a Myth or Truth ?</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">
                  Is Web 3.0 a Myth ? or Truth ?
                </h1>
                <div className="ptf-single-post__meta">
                  <span className="cat">By</span>
                  <span className="date">Dr. Fahmida Yeasmin for Broken Pie</span>
                </div>
              </div>
            </header>


            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    {/* <!--Post Info--> */}
                    <div className="ptf-single-post__info">
                      <a className="author" href="#">
                        <i className="lnil lnil-user"></i>by <span>Dr. Fahmida Nishat Yeasmin</span>
                      </a>
                      <a className="view" href="#">
                        <i className="lnil lnil-eye"></i>1.7k+ Views
                      </a>
                      <a className="comments" href="#">
                        <i className="lnil lnil-comments"></i>0 Comments
                      </a>
                      <a className="report" href="#">
                        <i className="lnil lnil-warning"></i>Report
                      </a>
                    </div>

                    {/* <!--Post Excerpt--> */}
                    <div className="ptf-single-post__excerpt">
                      In a Web3 world, people control their own data and bounce around from social media to email to shopping using a
                      <span className="has-accent-1"> single personalized account, </span>
                      creating a public record on the blockchain of all of that activity.
                    </div>

                    {/* <!--Post Content--> */}
                    <div className="ptf-single-post__content">
                      <p>
                        Web 3.0 on instagram, web 3.0 on twitter, web 3.0 everywhere. It seems like web 3.0 has taken
                        the world by storm. There have been a lot of predictions about how web 3.0 will change the
                        future of technology and sweep us off our feet but very few actually understand the true
                        meaning and also the changes it underwent while its transformations from web 1.0 and web 2.0.
                        Web 1.0 whose roots go back to from the 90s to mid 2000s is a one-dimensional internet which
                        only had one function i.e to read. There was no posting, sharing or interacting with other people.
                        Web 2.0 which states back from the mid 2000s to late 2010s is a two dimensional internet.
                        Examples of which include Facebook, instagram, snapchat etc.<br /><br />
                        Web 3.0 which started from the 2020s has the potential to not just be three-dimensional but a
                        multi-dimensional internet. It is a decentralized web which runs on blockchains, driven by a
                        peer-to-peer network. It can have a large amount of information and transform it into useful
                        actions for people. It depends on the use of machine learning and artificial intelligence. Apple’s
                        Siri and Alexa are some examples of web 3.0. It tries to create more open-sourced and
                        intelligent websites and web applications with a focus on revolutionizing machine learning to
                        create more than just technological advances and expanding its roots into political as well as
                        philosophical advances.<br /><br />
                        Decentralization has become the central concept of web 3.0 but the degree of decentralization
                        is not a binary state. Hence the degree of decentralization will depend more on the application.
                        Web 3.0 includes more than just cryptocurrencies, blockchains and other products of crypto
                        economic design. It consists of any technology that helps in reforming the centralized internet. It
                        is not a tough job to envision a future where crypto-based phones, cryptocurrency wallets,
                        decentralized storage and VPNs would be widespread. A future where there will be no central
                        point of control and no need of network or cellular providers that suspend or surveil our
                        information. A trustless platform where our personal data will be fully encrypted and only the
                        end user will have complete control over their data rather than big companies or single
                        individuals or entities.<br /><br />
                        In conclusion we can say that web 3.0 is inevitable. Its potential future might face a lot of
                        barriers. Cryptocurrencies might fail but the idea of web 3.0 would not die. It is a vision of what
                        technology could do because it drags our attention towards the loopholes caused by the
                        centralisation of the internet. Web 3.0 has given us a chance to create the internet on a whole
                        new level by putting constraints on power and control according to its design and requirements.
                      </p>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "5rem", "--ptf-md": "2.5rem" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default IsWeb3Myth;
