import React from "react";
import { Link } from "react-router-dom";

const blogContent = [
  {
    img: "post-1",
    cat: "Blockchain",
    date: "Feb 15, 2023",
    title: "The world of Blockchain and Its Future",
    description: `The blockchain is a “trustless system” which means that no trust is required between the
    participants in a transaction because it verifies each transaction through the...`,
    url: "/the-world-of-blockchain-and-future"
  },
  {
    img: "post-2",
    cat: "Logical Thinking",
    date: "Dec 7, 2022",
    title: "Web 3.0. Truth or Myth ?",
    description: `Decentralization has become the central concept of web 3.0 but the degree of decentralization
    is not a binary state. Hence the degree of decentralization will depend more on...`,
    url: '/web3-truth-myth'
  },
  {
    img: "post-3",
    cat: "Robotics",
    date: "Dec 2, 2022",
    title: "AI & Robotics. Whats in the Future ?",
    description: `One of the most interesting aspects of AI and robotics are the humanoid robots. They have
    body shape and features similar to humans. They are mostly used to space research...`,
    url: '/ai-robotics-future'
  },
];

const Blog = () => {
  return (
    <>
      {blogContent.map((val, i) => (
        <div className="col-xl-4 col-lg-4" key={i}>
          <div className="grid-item filter-1 filter-4">
            {/* <!--Blog Post--> */}
            <article className="ptf-post ptf-post--style-4">
              <div className="ptf-post__media">
                <img
                  src={`assets/img/home/default/${val.img}.png`}
                  alt=""
                  style={{
                    borderRadius: 10
                  }}
                  loading="lazy"
                />
                <div className="ptf-post__media__content">
                  <header className="ptf-post__header">
                    <div className="ptf-post__meta">
                      <span className="cat">{val.cat}</span>
                    </div>
                    <h3 className="ptf-post__title">{val.title}</h3>
                  </header>
                </div>
              </div>
              <div className="ptf-post__content">
                <header className="ptf-post__header">
                  <div className="ptf-post__meta">
                    <span className="cat">{val.cat}</span>
                  </div>
                  <h3 className="ptf-post__title">
                    <Link to={val.url}>{val.title}</Link>
                  </h3>
                </header>
                <div className="ptf-post__excerpt">
                  <p>{val.description}</p>
                </div>
                <footer className="ptf-post__footer">
                  <Link className="ptf-read-more-link" to={val.url}>
                    Continue
                  </Link>
                </footer>
              </div>
            </article>
          </div>
        </div>
      ))}
    </>
  );
};

export default Blog;
