import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const portfolioMenu = [
  "We have completed 200+ Projects so far. Only large scale projects are listed below"
];

const portfolioContent = [
  {
    tabContent: [
      {
        cat: "Blockchain Based Ride Hailing",
        title: "Snog Cab",
        date: "2023",
        routerLink: "/portfolio-details",
        imgPopup: "work-1",
        dataId: "work-1",
      },
      {
        cat: "Blockchain",
        title: "HNI Protocol",
        date: "2023",
        routerLink: "/portfolio-details",
        imgPopup: "work-1",
        dataId: "work-1",
      },
      {
        cat: "Crypto Exchanger (Beta Phase)",
        title: "Saga Reserve",
        date: "2022",
        routerLink: "/portfolio-details",
        imgPopup: "work-1",
        dataId: "work-1",
      },
      {
        cat: "NFT Marketplace",
        title: "AGRF",
        date: "2022",
        routerLink: "/portfolio-details",
        imgPopup: "work-2",
        dataId: "work-2",
      },
      {
        cat: "Robotics",
        title: "Robo Arm",
        date: "2021",
        routerLink: "/portfolio-details",
        imgPopup: "work-6",
        dataId: "work-6",
      },
      {
        cat: "Online Education",
        title: "Smart Student Life",
        date: "2021",
        routerLink: "/portfolio-details",
        imgPopup: "work-5",
        dataId: "work-5",
      }
    ],
  },
];

const PortfolioListing = () => {
  return (
    <>
      <Tabs>
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <TabList className="ptf-filters ptf-filters--style-3">
            {portfolioMenu.map((item, i) => (
              <Tab className="filter-item " key={i}>
                {item}
              </Tab>
            ))}
          </TabList>
        </div>

        {/* <!--Spacer--> */}
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
        ></div>

        {portfolioContent.map((item, i) => (
          <TabPanel key={i}>
            {/* <!--Animated Block--> */}
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <div className="ptf-isotope-grid">
                <div className=" grid-item ">
                  {/* <!--Portfolio Item--> */}
                  {item.tabContent.map((val, i) => (
                    <article
                      className="ptf-work ptf-work--style-4"
                      data-tip
                      data-for={val.dataId}
                      key={i}
                    >
                      <div className="ptf-work__category">{val.cat}</div>
                      <h4 className="ptf-work__title">{val.title}</h4>
                      <div className="ptf-work__date">{val.date}</div>
                    </article>
                  ))}
                </div>
                <div></div>
                {/* End .grid-item */}
              </div>
              {/* End .ptf-isotope-grid */}
            </div>

            {/* End portfolio */}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default PortfolioListing;
