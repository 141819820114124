import React from "react";
import { Link } from "react-router-dom";

const List = [
  "Software Development",
  "Robotics",
  "IoT & ITeS",
  "Blockchain",
  "Mobile Games",
  "Mobile Apps",
  "Cryptocurrency",
  "IT Consultation",
];

const ServiceList = () => {
  return (
    <>
      {List.map((val, i) => (
        <li key={i}>
          <Link to="/services">{val}</Link>
        </li>
      ))}
    </>
  );
};

export default ServiceList;
