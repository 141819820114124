import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";


const ContactForm = () => {
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    organization: Yup.string().required("Organization name is required"),
    projectGoal: Yup.string().required("Please, write your project goal."),
    timeline: Yup.string().required("Pleae write if you have timeline."),
    phone: Yup.string().required("Phone No is required"),
    interested: Yup.string().required('Please select area of interest'),
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms and Conditions is required"
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  async function onSubmit(data, e) {
    // display form data on success
    console.log(data.interested)
    if (!data.interested.length) {
      document.getElementById('interested').focus();
      return alert('Please select area of interest.')
    }
    document.getElementById('result').innerHTML = '<strong class="text-danger">Wait a Moment...</strong>';

    const Text = 'Name: ' + data.name + ', Organization:' + data.organization + ', Project Goal: ' + data.projectGoal + ', Timeline: ' + data.timeline + ', Budget: ' + data.budget + ', Phone: ' + data.phone + '. Email:' + data.email;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var channel = "C04Q9P8A38D";
    if (data.interested === 'nbfc') {
      channel = 'C04RVGK39MZ';
    }
    if (data.interested === 'blockchain' || data.interested === 'software') {
      channel = 'C04RSMV40F7';
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("channel", channel);
    urlencoded.append("token", "xoxb-4700569679216-4831323629812-O3grO1lxVXTR2ZFdxef5lq4K");
    urlencoded.append("text", Text);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://slack.com/api/chat.postMessage", requestOptions);
    document.getElementById('result').innerHTML = '<strong class="text-success">Thank you for your message. We\'ll contact you shortly</strong>';
    document.getElementById('contactform').reset();
  }

  return (
    <form id="contactform" onSubmit={handleSubmit(onSubmit)} className="contact_form">
      <div className="ptf-form-group">
        <label data-number="01">What’s your name?</label>
        <input
          type="text"
          name="name"
          {...register("name")}
          className={`${errors.name ? "is-invalid" : ""}`}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="02">What’s the name of your organization?</label>
        <input
          type="text"
          name="organization"
          {...register("organization")}
          className={`${errors.organization ? "is-invalid" : ""}`}
        />
        {errors.organization && (
          <div className="invalid-feedback">{errors.organization?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="03">What’s your email address?</label>
        <input
          name="email"
          type="text"
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>
      <div className="ptf-form-group">
        <label data-number="04">What’s your Phone No ?</label>
        <input
          name="phone"
          type="text"
          {...register("phone")}
          className={` ${errors.phone ? "is-invalid" : ""}`}
        />
        {errors.phone && (
          <div className="invalid-feedback">{errors.phone?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="05">Tell us about your project goals.</label>
        <textarea
          type="text"
          name="projectGoal"
          {...register("projectGoal")}
          className={`${errors.projectGoal ? "is-invalid" : ""}`}
        />
        {errors.projectGoal && (
          <div className="invalid-feedback">{errors.projectGoal?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="06">Do you have a timeline in mind?</label>
        <input
          type="text"
          name="timeline"
          {...register("timeline")}
          className={`${errors.timeline ? "is-invalid" : ""}`}
        />
        {errors.timeline && (
          <div className="invalid-feedback">{errors.timeline?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="07">What have you budgeted for this project?</label>
        <select
          name="budget"
          {...register("budget")}
          className={`${errors.budget ? "is-invalid" : ""}`}
        >
          <option>Below $500 USD</option>
          <option>$500 - $1,000 USD</option>
          <option>$1,000 - $5,000 USD</option>
          <option>$5,001 - $10,000 USD</option>
          <option>$10,001 - $50,000 USD</option>
          <option>$50,001+ USD</option>
          <option>I have no Idea</option>
        </select>
        {errors.budget && (
          <div className="invalid-feedback">{errors.budget?.message}</div>
        )}
      </div>
      <div className="ptf-form-group">
        <label data-number="08">Where you are interested in ?</label>
        <select
          name="interested"
          id="interested"
          required
          {...register("interested")}
          className={`${errors.interested ? "is-invalid" : ""}`}
        >
          <option selected value={''}>Select the field of your interest</option>
          <option value={'blockchain'}>Blockchain</option>
          <option value={'software'}>Software & Game Development</option>
          <option value={'mlm'}>Network Marketing Software</option>
          <option value={'nbfc'}>NBFC, Microfinance, Banking & Other</option>
        </select>
        {errors.interested && (
          <div className="invalid-feedback">{errors.interested?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>

      <div className="ptf-form-group agreement-checkbox ">
        <input
          name="acceptTerms"
          type="checkbox"
          id="acceptTerms"
          {...register("acceptTerms")}
          className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
        />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span>I agree to the Terms and Conditions and Privacy Policy.
        </label>
        {errors.acceptTerms && (
          <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }}></div>
      <div id="result" className="mt-1 mb-1"></div>
      <button id="submit" className="ptf-submit-button">
        Submit
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 17 17"
        >
          <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
        </svg>
      </button>
      {/* End .ptf-submit-button */}
    </form>
  );
};

export default ContactForm;
