import React from "react";
import { ivrphone, softwarephone, technicalsupportphone, tollfreeindia, tollfreeusa } from "../Const";

const Address = () => {
  return (
    <>
      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Address (Assam)
        </h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          1st Floor, HN 9, Sarvodaya Path, Opp Rajib Bhawan
          <br />
          ABC, Guwahati, Assam, India - 781005
        </p>
      </div>
      
      <div className="ptf-animated-block mt-4">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Address (UAE)
        </h5> 
      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          Level 17, World Trade Centre, Hamdan Street
          <br />
          Abu Dhabi, United Arab Emirates
        </p>
      </div>
      <div className="ptf-animated-block mt-4">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Coimbatore, Tamilnadu
        </h5> 
      <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
        G-2E/12/1, Ground Floor, Manchester Square, Puliakulam Rd,
          <br />
          near Coimbatore Kidney Center, Coimbatore – 641037
        </p>
      </div>

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.1875rem" }}></div>
      <blockquote style={{
        borderLeft: 5,
        borderLeftStyle: 'solid',
        borderLeftColor: 'green',
        fontSize: 15,
        paddingLeft: 10,
        fontWeight: 300,
      }}>
        All the contact options mentioned below are for projects below $5000 USD only. For projects $5000 USD and above, a dedicated support number will be provided.
      </blockquote>

      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Email</h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          <a href="mailto:hello@brokenpie.com">
            hello@brokenpie.com
            <br />
          </a>
        </p>
      </div>

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.1875rem" }}></div>
      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Phone (Sales)
        </h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          <a href={'tel:' + softwarephone.replace(/ /g, '')}>{softwarephone} (Sales 1)</a><br />
          <a href={'tel:' + ivrphone.replace(/ /g, '')}>{ivrphone} (Sales 2)</a><br />
          <br />
          <a href={'tel:' + tollfreeindia}>{tollfreeindia} (Tollfree India)</a>
          {tollfreeusa !== null && <a href={'tel:' + tollfreeusa}>{tollfreeusa} (Tollfree USA)</a>}
        </p>
      </div>
      <div className="ptf-animated-block mt-4">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Phone (After Sales Support)
        </h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "1.25rem" }}></div>
        <p className="fz-20 lh-1p5 has-black-color">
          <a href={'tel:' + technicalsupportphone.replace(/ /g, '')}>{technicalsupportphone}</a> (After Sales)<br />
          <h5 className="fz-14 text-uppercase has-3-color fw-normal">
            (Wired: 11 AM to 5:30 PM IST)
          </h5>
        </p>
      </div>

      {/* <!--Spacer--> */}
      <div
        className="ptf-spacer"
        style={{ "--ptf-lg": "4.375rem", "--ptf-md": "2.1875rem" }}
      ></div>
    </>
  );
};

export default Address;
