import React from 'react'

export default function Pricing() {
    return (
        <div>
            <h3>Pricing & Technology</h3>
            {/* <!--Spacer--> */}
            <div
                className="ptf-spacer mt-5"
                style={{ "--ptf-xxl": "1.875rem" }}
            ></div>
            <div style={{
                border: `4px solid #209e00`,
                padding: 20,
                borderRadius: 10
            }}>
                <h4 style={{
                    fontWeight: 300
                }}>When it comes to technology, no one can beat <strong>Broken Pie Technology ™</strong>. We may be first in the world to use AI, Neural Network, etc. even in the smallest project if required. We can take guarantee that our projects can handle billion users/transaction load from the day one and passes all the required security tests.</h4>
                <h5 className="mt-3 text-dark mb-4 mt-4">So Are We costly ?</h5>
                <h4 style={{
                    fontWeight: 300
                }}> We promise you to make your project at <strong>50% lower cost</strong> than the market, you can compare. We can achieve it because we use Artificial Intelligence and Robot Programming wherever possible, This not only make your project more secure and fast, but also reduce human error and development to production time.</h4>
            </div>
        </div>
    )
}
