import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "About",
    activeClass: "sf-with-ul",
    menuClass: "two-columns",
    dropDownItems: [
      {
        name: "Know Us",
        routerPath: "/about",
      },
      {
        name: "Overview",
        routerPath: "/overview",
      },
      {
        name: "Non Profit Works",
        routerPath: "/ngo",
      },
      {
        name: "Career",
        routerPath: "/career",
      },
      {
        name: "Media",
        routerPath: "/media",
      },
      {
        name: "BPie Guaranteed",
        routerPath: "/bpie-gurranted",
      },
    ],
  },
];

const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      <li className={`menu-item`}>
        <a href="/">
          <span>Home</span>
        </a>
      </li>
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          <a href="/" className={item.activeClass}>
            <span>{item.name}</span>
          </a>

          <ul className="sub-menu mega">
            {item.dropDownItems.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath}>
                  {" "}
                  <span>{val.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </li>
      ))}

      <li className={`menu-item`}>
        <a href="/apps">
          <span>Showcase</span>
        </a>
      </li>
      <li className={`menu-item`}>
        <a href="/services">
          <span>Services</span>
        </a>
      </li>
      <li className={`menu-item`}>
        <a href="/contact">
          <span>Contact</span>
        </a>
      </li>
    </ul>
  );
};

export default DropdownMenu;
