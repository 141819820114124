import React, { useState } from 'react'



export default function SlackForm() {

    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [interested, setInterested] = useState(null);
    const [contacttime, setContacttime] = useState('Anytime');
    const [isloading, setIsloading] = useState(false);

    const processForm = async () => {
        if (!phone) {
            return alert('Phone No is Required');
        }
        if (!name) {
            return alert('Name is Required');
        }
        if (!email) {
            return alert('Email ID is Required');
        }
        if (!interested) {
            return alert('Interest field is Required');
        }

        var channel = "C04Q9P8A38D";
        if (interested == 'nbfc') {
            channel = 'C04RVGK39MZ';
        }
        if (interested == 'blockchain' || interested == 'software') {
            channel = 'C04RSMV40F7';
        }

        setIsloading(true);
        const Text = 'Name: ' + name + ', Email:' + email + ', Phone: ' + phone + ', Interested in: ' + interested + ', Best time to Contact: ' + contacttime;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("channel", channel);
        urlencoded.append("token", "xoxb-4700569679216-4831323629812-O3grO1lxVXTR2ZFdxef5lq4K");
        urlencoded.append("text", Text);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        await fetch("https://slack.com/api/chat.postMessage", requestOptions);
        document.getElementById('result').style.display = 'inline-block';
        document.getElementById('dysus').reset();
        setIsloading(false);
    }


    return (
        <div style={{
            maxWidth: 500,
            width: '100%'
        }}>
            <h3>Let us Contact You</h3>
            {/* <!--Spacer--> */}
            <h6 style={{
                fontWeight: 200,
                marginTop: 20
            }}>You may fill this form and our technology expert will contact you for further discussion. Note that we do not spam you by calling multiple time or by asking for an update every day..</h6>
            <div
                className="ptf-spacer mt-5"
                style={{ "--ptf-xxl": "1.875rem" }}
            ></div>
            <div style={{
                border: `4px solid #430694`,
                padding: 20,
                borderRadius: 10
            }}>
                <form id='dysus'>
                    <label>Your Good Name</label>
                    <input className='form-control mb-4' autoComplete={false} onChange={(text) => {
                        setName(text.target.value)
                    }} placeholder='Your Name' type={'text'} />
                    <label>Phone No</label>
                    <input className='form-control mb-4' autoComplete={false} onChange={(text) => {
                        setPhone(text.target.value)
                    }} placeholder='Your Phone No' type={'tel'} />
                    <label>Email Address</label>
                    <input className='form-control mb-4' autoComplete={false} onChange={(text) => {
                        setEmail(text.target.value)
                    }} placeholder='Your Email' type={'email'} />
                    <label>Interested In</label>
                    <select autoComplete={false} onChange={(text) => {
                        setInterested(text.target.value)
                    }} className='form-control mb-4'>
                        <option value={undefined}>Select your interest Here</option>
                        <option value={'blockchain'}>Blockchain</option>
                        <option value={'software'}>Software & Game Development</option>
                        <option value={'mlm'}>Network Marketing Software</option>
                        <option value={'nbfc'}>NBFC, Microfinance, Banking & Other</option>
                    </select>
                    <label>Good time to Contact You</label>
                    <select onChange={(text) => {
                        setContacttime(text.target.value)
                    }} className='form-control mb-4'>
                        <option value={'anytime'}>Anytime</option>
                        <option value={'Before 12 PM'}>Before 12 PM</option>
                        <option value={'12PM - 4PM'}>12PM - 6PM</option>
                        <option value={'After 6PM'}>After 6PM</option>
                    </select>
                    {isloading ? <button type='button' className='btn btn-loading btn-lg btn-secondary btn-disabled'>Wait a Moment..</button> : <button onClick={() => {
                        processForm()
                    }} className='btn btn-lg btn-primary'>Contact Me</button>
                    }
                    <div id='result' style={{
                        display: 'none',
                        backgroundColor: '#430694',
                        color: '#fff'
                    }} className='alert mt-3'>
                        Thank you for your interest, we'll contact you soon.
                    </div>
                </form>
            </div>
        </div>
    )
}
