import React from "react";
import { Helmet } from "react-helmet";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import HeaderDefault from "../../../components/header/HeaderDefault";
import ServiceOne from "../../../components/service/ServiceOne";

const AboutUs = () => {
  return (
    <div className="ptf-site-wrapper animsition  ptf-is--about-us">
      <Helmet>
        <title>Broken Pie - About Us</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDefault />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--about-us">
            {/*=============================================
                Start About Us Hero Section
              ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
              ></div>

              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-xl-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">B'Pie</h1>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.8125rem" }}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <p className="fz-30 fw-bold text-uppercase has-3-color">
                        The Software <br />
                        Company
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                    >
                      <a href="/brokenpie.pdf" target={'_blank'} className="btn btn-outline-success mt-4">Download Presentation &nbsp;&nbsp;<i className="lnil lnil-download"></i></a>
                    </div>
                    <div className="row">
                      <div className="col-xl-8">
                        <div style={{ maxWidth: "29.6875rem" }}>
                          {/* <!--Animated Block--> */}
                          <div
                            className="ptf-animated-block"
                            data-aos="fade"
                            data-aos-delay="200"
                          >
                            {/* <!--Divider--> */}
                            <div className="ptf-divider"></div>
                            {/* <!--Spacer--> */}

                            <div
                              className="ptf-spacer"
                              style={{
                                "--ptf-xxl": "4.375rem",
                                "--ptf-md": "2.1875rem",
                              }}
                            ></div>
                            <p className="fz-24">
                              At Broken Pie, we pride ourselves on our commitment to excellence and our ability to deliver innovative software solutions that meet the unique needs of our clients. Whether you're looking to build a custom software application or improve an existing one, we have the expertise and experience to deliver results.
                              <br />
                              <strong>Our team is composed of</strong> software engineers, ethical hackers, designers, project managers, and quality assurance specialists who work together to ensure that every project we undertake is completed on time and to the highest possible standard. We use the latest technologies and industry best practices to create software that is not only effective but also scalable and adaptable to changing business needs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xl": "5.625rem" }}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="300"
                    >
                      <div className="text-center">
                        {/* <!--Mask Image--> */}
                        <div className="ptf-mask-image">
                          <img
                            src="assets/img/root/about-us/about-us-main-image-mask.png"
                            alt="layer"
                            loading="lazy"
                            className="lay2"
                            style={{
                              zIndex: "1",
                            }}
                            srcSet="assets/img/about-us-main.jpg"
                          />
                          <img
                            src="assets/img/root/about-us/about-us-main-image-layer-1.png"
                            alt="layer"
                            loading="lazy"
                            style={{ zIndex: "2" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div className="ptf-divider"></div>
              </div>
            </section>

            {/*=============================================
                Start Service Section
              ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-lg-3">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading">
                        Our <br />
                        Roadmap
                      </h2>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "2.5rem" }}
                      ></div>
                      <p className="fz-18">
                        We have started our journey in the year of 2020 with a mission to make the world a digital globe with various Profitable and Non-Profitable works. Now we have grown to 50+ Software Engineers in 3 locations in the world, with a vision to grow to 500+ engineers in the coming 3 year. At Broken Pie, we believe in fostering strong partnerships with our clients, built on collaboration, communication, and trust. We work closely with you to understand your specific needs and objectives, and provide you with regular updates and progress reports throughout the development process. Our commitment to quality and customer satisfaction means that we always deliver on time and on budget, without compromising on the quality of our work.
                        <hr />
                        Whether you're a startup or a large enterprise, we have the expertise and experience to help you take your business to the next level. Contact us today to schedule a consultation and see how we can help you achieve your goals.
                      </p>
                    </div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{
                        "--ptf-lg": "4.375rem",
                        "--ptf-md": "2.1875rem",
                      }}
                    ></div>
                  </div>
                  <div className="col-lg-8 offset-lg-1">
                    <ServiceOne />
                  </div>
                </div>
              </div>
              {/* End .row */}

              {/* <!--Spacer--> */}

              {/* End .container-xxl */}
            </section>
            {/* End service */}
          </div>
        </div>
        {/* End ptf-main */}

        {/* <!--Footer--> */}
        <footer className="ptf-footer ptf-footer--style-1">
          <div className="container-xxl">
            <div className="ptf-footer__top">
              <FooterThree />
            </div>
            <div className="ptf-footer__bottom">
              <CopyRightThree />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AboutUs;
