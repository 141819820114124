import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../../components/header/HeaderDefault";
import CopyRight from "../../../../components/footer/copyright/CopyRight";
import Footer from "../../../../components/footer/Footer";
import ServiceListThree from "../../../../components/list/ServiceListThree";
import Faq from "../../../../components/faq/Faq";
import Skills from "../../../../components/skill/Skills2";
import Approach2 from "../../../../components/service/Approach2";
import { Link } from "react-router-dom";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { nbfcphone, tollfreeindia, tollfreeusa } from "../../../../Const";

const NBFCSoftware = () => {
  return (
    <div>
      <Helmet>
        <title>NBFC & Loan Management Software Starts only $1800 USD</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderDefault />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-9">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3 className="large-heading">
                      NBFC, Nidhi, Micro finance & Loan Management Software <br /> <span style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        borderBottomColor: '$000',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid'
                      }}>@ ₹ 1.5 Lakh INR ($ 1800 USD)</span> <sup style={{ fontSize: 14, color: "green" }}>Starts</sup>
                    </h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "2.5rem" }}
                    ></div>
                    <p className="fz-18">
                      A fully managed, highly scalable NBFC Software having below features:
                      <ol>
                        <li>Loan Management (Personal, Asset, Land etc)</li>
                        <li>RD/FD and Other Deposit Management</li>
                        <li>Agent, Employee and User Management</li>
                        <li>CIBIL Score API</li>
                        <li>Payment Gateway, Aadhar Verification and much more</li>
                        <li>EMI, Commission, Fee and Complete finance management</li>
                        <li>Saving Account management</li>
                        <li>Mobile Application (iOS & Android)</li>
                        <li>AI Chat bot</li>
                        <li>Payment Gateway and Payout Gateway Integration</li>
                        <li>Recovery Management</li>
                        <li>Payroll & Attendance</li>
                        <li>KYC & Policies Management</li>
                        <li>And all related others</li>
                      </ol>
                      <hr />
                      <Link to={"/contact"}>
                        <a className="btn btn-primary">
                          Contact Us &rarr;
                        </a>
                      </Link>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                  ></div>
                </div>
                <div className="col-lg-12 mb-4">
                  {/* <!--Animated Block--> */}
                  <AwesomeSlider>
                    <div>
                      <img src="https://i.imgur.com/YUkXpzG.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/qhFAGkc.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/M3LICOw.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/oYR5wOf.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/wAJawWC.png" />
                    </div>
                    <div>
                      <img src="https://i.imgur.com/LPEtpKx.png" />
                    </div>
                  </AwesomeSlider>
                </div>
              </div>
            </div>
          </section>
          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3 style={{
                      fontWeight: 400,
                      marginBottom: 40,
                      marginTop: 40
                    }}>Call our Sales Team</h3>

                    <p className="fz-18" style={{
                      backgroundColor: '#1E1E1E',
                      padding: 20,
                      borderRadius: 10,
                      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`
                    }}>
                      <h4>
                        <a className="text-white" href={'tel:' + nbfcphone.replace(/ /g, '')}>{nbfcphone}</a><br />
                      </h4>
                      <h4 style={{
                        fontWeight: 400
                      }} className="mt-3">
                        <a className="text-white" href={'tel:' + tollfreeindia}>{tollfreeindia} (Tollfree India)</a>
                        {tollfreeusa !== null && <a href={'tel:' + tollfreeusa}>{tollfreeusa} (Tollfree USA)</a>}
                      </h4>
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "4.375rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Specialization</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      So far dozones of Micro finance companies using our application to power their banking needs through technology. Not only this saving them from manpower expenses but also helping them to do business smoothly and leaving all tehcnical barden to us. Our NBFC software can handle upto 10,000 Transactions per second. Want more ? Give us a Call.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.125rem" }}
                  ></div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Skills</h3>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.75rem" }}
                  ></div>
                  <div className="ptf-skill-box-grid">
                    <Skills />
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "6.875rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3>Our Approach</h3>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.875rem" }}
                    ></div>
                    <p className="fz-18">
                      This is how we handle your NFT project order. Our
                      systematic approach helps us to understand your
                      requirement and help us to complete your project
                      efficiently.
                    </p>
                  </div>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "5.625rem" }}
                  ></div>
                  {/* <!--Process Steps--> */}
                  <Approach2 />
                </div>
                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">Why Us ?</h4>
                        <ServiceListThree />
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      {/* End ptf-main */}

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NBFCSoftware;
