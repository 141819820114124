import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: " How much does software cost?",
    desc: `The cost of our basic MLM Software is just Rs. 10,000 INR or $130 USD. However, the cost may increase if your project plan is complicated or decentralised. எங்கள் அடிப்படை MLM மென்பொருளின் விலை வெறும் ரூ. 10,000 INR அல்லது $130 USD. இருப்பினும், உங்கள் திட்டத் திட்டம் சிக்கலானதாகவோ அல்லது பரவலாக்கப்பட்டதாகவோ இருந்தால் செலவு அதிகரிக்கலாம்.`,
    expand: "a",
  },
  {
    title: "How about payment?",
    desc: `For each project, generally 50% payment has to be made in advance against the quotation. However, negotiation is possible. Please contact us in advance. ஒவ்வொரு திட்டத்திற்கும், பொதுவாக மேற்கோளுக்கு எதிராக 50% கட்டணத்தை முன்கூட்டியே செலுத்த வேண்டும். இருப்பினும், பேச்சுவார்த்தை சாத்தியமாகும். தயவுசெய்து எங்களை முன்கூட்டியே தொடர்பு கொள்ளவும்.`,
    expand: "b",
  },
  {
    title: "How long support that I get?",
    desc: `After Sales support (error & bug fixes) is available for  lifetime without any cost. However, general & security maintenance is just for 1 year from the date of delivery of your project. தொழில்நுட்ப ஆதரவு (பிழை மற்றும் பிழை திருத்தங்கள்) வாழ்நாள் முழுவதும் எந்த செலவும் இல்லாமல் கிடைக்கும். இருப்பினும், பொது மற்றும் பாதுகாப்பு பராமரிப்பு என்பது உங்கள் திட்டம் டெலிவரி செய்யப்பட்ட நாளிலிருந்து 1 வருடத்திற்கு மட்டுமே.`,
    expand: "c",
  },
  {
    title: "If I not satisfy with your service, can I get a refund?",
    desc: `Of course you'll get a full refund if we do not deliver the project we have promised (written promise will be given along with the quotation). நாங்கள் உறுதியளித்த திட்டத்தை நாங்கள் வழங்கவில்லை என்றால், நிச்சயமாக நீங்கள் முழுப் பணத்தைத் திரும்பப் பெறுவீர்கள் (மேற்கோளுடன் எழுத்துப்பூர்வ வாக்குறுதியும் வழங்கப்படும்).`,
    expand: "d",
  },
  {
    title: "Do you provide NDA Agreement?",
    desc: `There will be a Non Disclosure Agreement with every project. NDA agreement will be provided along with the quotation. ஒவ்வொரு திட்டத்திலும் வெளிப்படுத்தாத ஒப்பந்தம் இருக்கும். மேற்கோளுடன் NDA ஒப்பந்தம் வழங்கப்படும்.`,
    expand: "e",
  },
];

const Faq2 = () => {
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd>{item.desc}</dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq2;
