import React, { useEffect } from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1200 });

    // Google Tag Manager Script
    const gtmScript = document.createElement('script');
    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-10897741103";
    gtmScript.async = true;
    document.body.appendChild(gtmScript);

    // Define dataLayer and gtag for Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { window.dataLayer.push(arguments) };
    window.gtag('js', new Date());
    window.gtag('config', 'AW-10897741103');

    // TrafficGuard Script
    const tgScript = document.createElement('script');
    tgScript.type = 'text/javascript';
    tgScript.async = true;
    tgScript.src = '//tgtag.io/tg.js?pid=tg-013196-001';
    document.body.appendChild(tgScript);

    // Initialize TrafficGuard data
    window.dataTrafficGuard = window.dataTrafficGuard || [];
    window.dataTrafficGuard.push(['property', 'tg-013196-001']);
    window.dataTrafficGuard.push(['event', 'pageview']);

    // Cleanup function for scripts on component unmount
    return () => {
      document.body.removeChild(gtmScript);
      document.body.removeChild(tgScript);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Broken Pie - The Software Company</title>
        <meta
          name="description"
          content="Broken Pie - The Software Company working in the field of Blockchain, Software & Game Engineering, AI and Robotics"
        />
        <meta
          name="keywords"
          content="blockchain, robotics, AI, software, mobile application, game"
        />
      </Helmet>

      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="220, 53, 69"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />

      <ScrollToTop />

      <Routes />
    </>
  );
};

export default App;
