import React from "react";

const approachContent = [
  {
    title: "Discussion",
    descriptions: `In this step, we'll learn about your requirement or interest and thoroughly discuss every steps associated and prepare a project plan, workflow and quotation for you.`,
    delayAnimation: "0",
  },
  {
    title: "Development",
    descriptions: `Here we'll start your dream project work as per the prepared project plan and will deliver you within the given deadline.`,
    delayAnimation: "100",
  },
  {
    title: "Branding",
    descriptions: `We'll also help you to brand your project internationally and bring investors for your awesome project if required.`,
    delayAnimation: "200",
  },
  {
    title: "Marketing",
    descriptions: `We'll also help you to do Gurranted ROI marketing of your project internationally to make your project successful.`,
    delayAnimation: "300",
  },
];

const Approach3 = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default Approach3;
