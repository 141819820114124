import React from "react";
import { Link } from "react-router-dom";

const pricingContent = [
  {
    planName: "Start Up",
    price: "10,000",
    priceDuration: "Onetime",
    pricingFeatures: [
      "Woo Commerce Only",
      "Handles ~ 10k users daily",
      "2 Payment gateway integration",
      "Hybrid Mobile app",
    ],
    badge: "",
    badgeClass: "",
    delayAnimation: "0",
  },
  {
    planName: "Business",
    price: "30,000",
    priceDuration: "Onetime",
    pricingFeatures: [
      "Woocommerce, Standalone (php)",
      "Handles ~ 20k users daily",
      "3 Payment gateway integration",
      "PWA mobile app",
      "B2B/B2C Platform",
      "3rd Party app integration (API)",
    ],
    badge: "Popular",
    badgeClass: "badge",
    delayAnimation: "100",
  },
  {
    planName: "Corporate",
    price: "80,000",
    priceDuration: "Onetime",
    pricingFeatures: [
      "Fully custom app",
      "Handles ~ 80k users daily",
      "Upto 6 payment gateway integration",
      "Native Mobile App",
      "B2B/B2C Platform",
      "3rd Party app integration (API)",
    ],
    badge: "",
    badgeClass: "",
    delayAnimation: "200",
  },
];

const EcomPricing = () => {
  return (
    <>
      {pricingContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block h-100"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Pricing Table--> */}
            <div className="ptf-pricing-table h-100">
              <div className={val.badgeClass}>{val.badge}</div>
              <div className="ptf-pricing-table__header">
                <h4 className="ptf-pricing-table__title">{val.planName}</h4>
              </div>
              <div className="ptf-pricing-table__price">
                <span className="currency">₹</span>
                <span className="price" style={{ fontSize: 30 }}>
                  {val.price}
                </span>
              </div>
              <div className="ptf-pricing-table__description">
                {val.priceDuration}
              </div>
              <div className="ptf-pricing-table__content">
                <ul>
                  {val.pricingFeatures.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default EcomPricing;
