import React from "react";
import { Link } from "react-router-dom";

const approachContent = [
  {
    subTitle: "ONE",
    title: "Software Development",
    descriptions: `From Web Application to Desktop and Mobile Application, We cover everything under Software Development. We use latest and smartest technology to deliver your dream projects.`,
    delayAnimation: "0",
    url:'/explore-software'
  },

  {
    subTitle: "TWO",
    title: "Game Development",
    descriptions: `Whether it is a Simple Web Based game or High end mobile game or a blockchain based game, Our experts are ready to deliver the world's smartest project, you can never imagine.`,
    delayAnimation: "100",
    url:'/game-development'
  },
  {
    subTitle: "THREE",
    title: "Blockchain Works",
    descriptions: `Want to develop a Cryptocurrency Project, Metaverse, Game, NFT or Exchange Project ? Or want to build own blockchain based dApp. We can play anything in the blockchain.`,
    delayAnimation: "200",
    url:'/explore-blockchain'
  },
  {
    subTitle: "FOUR",
    title: "IoT & AI",
    descriptions: `You have a dream project in Mind. We are expert to build AI based ITeS or IoT. Whether it is hardware based or software, We can deliver it with pinpoint perfection.`,
    delayAnimation: "300",
  },
  {
    subTitle: "FIVE",
    title: "Robotics",
    descriptions: `Be it an Industrial Robot or a IoT based small toy or anything that comes across your mind. We can fulfil all the unique concept in robotics, that you can ever imagine.`,
    delayAnimation: "400",
  },
  {
    subTitle: "SIX",
    title: "Consultancy",
    descriptions: `Starting a business or want to improve your running project for a greater performance and cost reduction? We can offer IT consultation for improving business quality and reduce cost.`,
    delayAnimation: "500",
  },
  {
    subTitle: "SEVEN",
    title: "Cyber Security",
    descriptions: `Want to audit the security of your application or whole organization ? Or want us to handle the security ? You are in the right place. We have a Highly Skilled team of ethical hackers and security administrators.`,
    delayAnimation: "500",
  }
];

const Approach = () => {
  return (
    <div
      className="row"
      style={{
        "--bs-gutter-x": "3.75rem",
        "--bs-gutter-y": "7.5rem",
      }}
    >
      {approachContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Approach Box--> */}
            <div
              className="ptf-approach-box"
              style={{
                "--ptf-title-color": "var(--ptf-color-white)",
              }}
            >
              <div className="ptf-approach-box__subtitle text-warning">{val.subTitle}</div>
              <h3 className="ptf-approach-box__title h1" style={{
                fontWeight: 200
              }}>{val.title}</h3>
              <div className="ptf-approach-box__content">
                <p className="text-white">{val.descriptions}</p>
              </div>
              <div>
                <br/>
              {val.url && <Link to={val.url} style={{
                     borderWidth: 2,
                     borderColor: '#fff',
                     backgroundColor: '#fff',
                     color: '#000',
                     borderStyle: 'solid',
                     padding: 5,
                     marginTop:10,
                     borderRadius: 4
                  }}>Explore &rarr;</Link>}
            </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Approach;
