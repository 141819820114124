import React from "react";
import SocialTwo from "../../social/SocialTwo";

const CopyRight = () => {
  return (
    <div className="row align-items-center">
      <div className="col-12 col-md">
        <p className="ptf-footer-copyright has-black-color">
          &copy; Broken Pie Private Limited <br /> CIN: U72900AS2020PTC020515
        </p>
      </div>
      {/* End .col */}

      <div className="col-12 col-md d-none d-xl-block"></div>
      {/* End .col */}

      <div className="col-12 col-lg">
        <div className="ptf-footer-socials has-black-color">
          {/* <!--Social Icon--> */}
          <SocialTwo />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default CopyRight;
